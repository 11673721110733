import React, { useEffect, useState } from "react";
// import { useLocation } from 'react-router-dom';
import axios from "axios";
// import {uri}  from '../help/Api';
// import {Locstors}  from '../Context/Locstors';
import { Apis } from "../help/Apis";
import { getLS, setLS } from "../utils/useLS";
import BackdropScI from "../services/BackdropScI";
import RePay from "./RePay";
import SignIn from "./SignIn";
import Main from "../Fronts/Main/Main";
import Endemam from "./Endemam";
import ReConnect from "./ReConnect";
// import Contact from "./Contact";
import Accsept from "./Accsept";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const inshState = {
  changip: "44444",
  endDigtip: "f.oraxcloud.com",
  ftp: "https",
  id: "",
  ip: "",
  ipinsh: "127.0.0.",
  leavle: "signIn",
  mode: 1,
  namedb: "",
  pass: "",
  port: "",
  server: "",
  typeConnct: true,
  useUri: true,
};

export default function BigenDemo() {
  // const loc = useLocation();
  const location = useLocation();
  // const viewTo = null;
  const [ViewT, setViewT] = useState(null);

  const [load, setLoad] = useState(true);

  const play = (isok, fp, leav, load) => {
    if (leav == "main") {
      if (isok == true) {
        if (fp == 0) setViewT(ToMain());
        else if (fp == 1) setViewT(ToRePay());
      } else if (isok == false) setViewT(ToReConnect());
    }
    if (leav == "endemam") {
      console.log("endemam");
      setViewT(ToEndemam());
    } else if (leav == "accsept" || leav == "undefined") {
      console.log("accsept 22");
      setViewT(ToAccsept());
    } else if (leav == "signIn") {
      // console.log(isok+'')
      console.log("signIn");
      if (isok == true) setViewT(ToSignIn());
      else if (isok == false) setViewT(ToReConnect());
    }

    // alert('load')
    setLoad(load);
  };

  useEffect(async () => {
    console.log("useEffect Bigen");

    let loc = getLS("infoConn", { useUri: false, leavle: "undefined" });
    if (!loc.useUri) {
      await setLS("infoConn", inshState);
      await setLS("showCurr", { statu: false, rout: "D" });
      loc = getLS("infoConn", {});
    }
    const uris = Apis();
    console.log("___loc___");
    console.log(loc);
    console.log("___loc___");
    //  alert(loc.useUri+'');

    loc.useUri
      ? // await axios.get('http://localhost:8184/api/ReLogin')
        await axios
          .get(uris + `ReLogin`)
          .then((res) => {
            //  alert('then');
            console.log("then");
            console.log(res.data);
            play(res.data.isok, res.data.fp, loc.leavle, res.data.load);
          })
          .catch((error) => {
            //  alert('catch');
            play(false, 0, loc.leavle, false);
          })
      : play(true, 0, loc.leavle, false);
  }, [location.key]);
  // }, []);
  return load ? <BackdropScI /> : <div> {ViewT} </div>;
}

const ToEndemam = () => {
  return <Endemam />;
};
const ToMain = () => {
  return <Main />;
};
const ToSignIn = () => {
  return <SignIn />;
};
const ToRePay = () => {
  return <RePay />;
};
const ToReConnect = () => {
  return <ReConnect />;
};
// const ToContact = () => { return ( <div> <Contact/> </div> ); };
const ToAccsept = () => {
  return <Accsept />;
};
// const ToMain = () => { return ( <div> <Main/> </div> ); };
