import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import GroupAPI, {defaultDG} from '../../../../api/Group';
import Groups from "./groups";

export default function GroupsCon(){

    const {data:groups, fetching: fetchinGroups, fetchURL} = useFetchURL({...{
                url: uri+'Groups',
            }});
    const {
        onChange,
        submit,
        update,
        remove,
        submitting, 
        errorMessage,
        onShow,
        onEnd,
        object,
        formType,
        
    } = useFormDataCRUD(
        GroupAPI.create,
        GroupAPI.update,
        GroupAPI.remove,
        undefined,
        defaultDG,
        fetchURL,
        fetchURL,
    );

    //   if(fetchinGroups)
    //     return <BackdropScI />
    return (
        <Groups
        {...{
            groups,
            onChange,
            submit,
            update,
            remove,
            submitting, 
            errorMessage,
            onShow,
            onEnd,
            object,
            formType,
        }}/>
    )
}

   