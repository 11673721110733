
 import React, { useState,useEffect } from 'react';

 import axios from 'axios';
 import {uri, }  from '../../../help/Api';

 import Grid from '@material-ui/core/Grid';
 import Typography from '@material-ui/core/Typography';
 import ButtonBase from '@material-ui/core/ButtonBase';
 //import {useStylesHed} from '../../../St/comps/UseStyle';
 import {useInvCa} from '../../../St/Reviews/UseStyle';

 function Footer(){
  const clas = useInvCa();
   //const clashd = useStylesHed();
  //  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
   const locDinv = JSON.parse(localStorage.getItem('datainv')) || {};

   const [found, setFound] = useState({});
   const [inv, setInv] = useState({});

  //  const inshInfoInv = {
  //   tot:'الاجمالي', totBefurDisc:'الإجمالي قبل الخصم',
  //   totDisc:'اجمالي الخصم', totAfterDisc:'الإجمالي بعد الخصم',
  //   totBefurAmount:'المجموع قبل الضريبة',totAmount:'إجمالي الضريبة',
  //   tax:'ضريبة القيمة المضافة',shaml:'الإجمالي يشمل القيمة المضافة',
  //   cash:'المدفوع',agel:'المتبقي',
  //   note:'البيان', tell:'رقم الهاتف',phone:'الجوال',
  //  }

  //  const [infoInv, setinfoInv] = useState(inshInfoInv);

   const getFound = async () => {
     axios.get(uri+'Foundation').then(res => { setFound(res.data); }).catch(error => { });
   }

 const getInv = async () => {
    axios.get(uri+`InvForFatora?proc_id=${locDinv.proc_id}`).then(res => { 
        setInv(res.data); }).catch(error => { });
  }

 useEffect(() => {  
    getFound();
    getInv();
 }, []);

 const sty= {
     cntr:{textAlign:'center', },
     lft:{textAlign:'left',padingLeft:5, },
     rit:{textAlign:'right',padingRight:5, },
     dirR:{ direction:"rtl",color:'black' },
     dirL:{ direction:"ltr", },
 }
 
     return (
      // <span style={{color:'black'}}>
    <Grid item container style={sty.dirR}>
       <Grid item container style={sty.cntr} spacing={0}>
         <br/>
        <Grid xs={12} >
           <Typography className={[clas.contntSub]}
           style={{margin:'0.5rem 0.1rem 0.5rem 0.1rem',borderRadius:5, color:'#5e5e5e',background:'#ffffee',padding:'0.5rem 0.5rem 0.5rem 0.5rem',}}
           >{found.Address}</Typography>
        </Grid>
    {
      inv.tax>0 && 
        <Grid item xs={12}>  
          <ButtonBase style={{border:'solid 0.1px',borderRadius:'3',margin:0,padding:0}}>
          <img src={`data:image/jpeg;base64,${inv.qr_image}`} style={{height:'57mm',width:'57mm'}} alt={found.nameA} /> 
         </ButtonBase>
        </Grid>
    }
        
  <Grid item xs={12}>{'....'}</Grid>
       </Grid>
    </Grid>
    // </span>
     );
   }
  export default Footer




// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import {uri, }  from '../../../../help/Api';
// import Grid from '@material-ui/core/Grid';
// import { Typography } from '@material-ui/core';


// function Footer(){
  
//   const [state, setState] = useState({});
//   const getInfo = async () => {
//   axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
// }

// useEffect(() => { 
//   getInfo();
// }, []);

//     return (
//     <Grid className="fixed-footer bg">
//       <Grid item container style={{textAlign:'center',direction:'rtl',}}>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المستخدم</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المحاسب</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المراجع</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المدير المالي</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المدير العام</Typography> <Typography className="box b cw"></Typography>
//         </Grid>

//         <Grid lg={12} md={12} sm={12} xs={12} >
//           <Typography className="cw b">{state.Address}</Typography>
//         </Grid>

//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">هاتف:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Tel}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">مبايل:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Mobile}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">فاكس:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Fax}</Typography></Grid>
//       </Grid>        
//     </Grid>
//     );
//   }

// export default Footer


