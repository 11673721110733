import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { colors } from "@material-ui/core";
import MaterialTable from "material-table";
import { cellS, headerS } from "../../../../St/comps/cStyle";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
}));

export default function SafeGrdTable(props) {
  const { data, columns, title } = props;
  const classes = useStyles();

  return (
    <MaterialTable
      style={{ boxShadow: "none" }}
      title="بحث عن الفواتير"
      // variant="outlined"
      options={{
        pageSize: 7,
        //showTitle:false,
        toolbar: false,
        sorting: false,
        showTitle: false,
        paging: false,

        headerStyle: headerS,
        cellStyle: cellS,
        maxBodyHeight: "50vh",
        minBodyHeight: "60vh",
      }}
      localization={{
        body: {
          emptyDataSourceMessage: (
            <h3
              style={{
                marginTop: "20vh",
                border: "solid 0px",
                boxShadow: "none",
              }}
            ></h3>
          ),
        },
      }}
      columns={columns}
      data={data}
      onRowClick={(event, rowData) => {
        event.stopPropagation();
      }}
    />
  );
}
