import React, { useState, useEffect } from 'react';
import { txtA, txtE } from '../../../Label/Lbl';
import BackdropScI from '../../../services/BackdropScI';
import axios from 'axios';
import { uri, } from '../../../help/Api'
import Grid from '@material-ui/core/Grid';
import MaterialTable from "material-table";
import { useInvAF, useInvMain } from '../../../St/Reviews/UseStyle';
// csG
import { stElemnt } from '../../../St/Reviews/cStyle';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {

    //border: 'solid 1px',
    borderRadius: '8px',
    // width: '100%',
    width: '22cm',
    marginLeft: '0.3cm',
    marginRight: '0.2cm',
    padding: '7px',

    // zIndex: 1,
  },
}));
const Body = () => {
  const clasess = useStyles();
  const loc = useLocation();
  const clasm = useInvMain();
  const clas = useInvAF();
  // const clasB = useInvBodyAF();

  const locInv = JSON.parse(localStorage.getItem('datainv')) || {};

  const [sta, setSta] = useState({ info: [], tot: {} });
  const [showSc, setShowSc] = useState(true);

  const getInfo = async () => {
    // alert('getInfo')
    const tb = {
      Rpt_Inv: true, wait: true,
      parms: {
        lprocid: locInv.proc_id, ibranchid: locInv.branch, iFoundid: 1,
        sProcTypeInvProc: locInv.ProcTypeInvProc,
      },
      autos: {},
    }
    console.log(tb)
    await axios.post(`${uri}Res`, tb)
      .then(res => {
        setSta({ info: res.data.RptInv, tot: res.data.infoBill });
        console.log('res.data.RptInv'); console.log(res.data);
        setShowSc(res.data.wait.state);
      })
      .catch(error => { console.log('catch'); });
  }
  useEffect(() => {
    // alert('getInfo')
    getInfo();
  }, []);


   var columns = [
    { title: "م", field: "id", },
    {title: "تاريخ القيد", field: "Date",},
    {title: "القيد", field: "proc_id",},
    {title: "البيان/الشرح", field: "notes",},
    {title: "رقم الفاتورة", field: "bill_no",},
    {title: "العميل", field: "customer_name",},
    {title: "الملف الضريبي", field: "",},
    {title: "المبلغ قبل الضريبة", field: "TotalBeforVat",},
    {title: "الضريبة", field: "TotalVat",},
    {title: "المبلغ بعد الضريبة", field: "TotalAfterVat",},
    {title: "", field: "",},
  ];
  const headerS = {
    height: '15px',
    color: '#fff',
    textAlign: '-webkit-center',
    margin: '0px',
    whiteSpace: 'nowrap',
    width: '0px',

    padding: '15px 0px',
    margin: '0px',
    whiteSpace: 'nowrap',

    border: 'solid 0.1px',
    // backgroundColor: '#20369f',
    backgroundColor: '#4997d0  ',
    padding: '7px 1px',
    marginLeft: 1, marginRight: 1,
  }

  return (
    showSc ? <BackdropScI /> :

      <Grid item container className={clas.Bdyroot} style={{ direction: 'rtl' }}>

        <Grid item xs={12}>



          <Grid item container>
            <Grid item xs={1}></Grid>
            <Grid item xs={10}>


            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>

        </Grid>

        <Grid item xs={12}>

          <Grid item container className={clasess.root}>
            <Grid item xs={3}>{`${loc.state.title} الخاضعة للنسبة الاساسية `}</Grid>
            <Grid item xs={3} >{'مركز التكلفة'} {'/'}{}</Grid>
            <Grid item xs={3} >{'من تاريخ'} {'/'}{loc.state.dateFrom}</Grid>

            <Grid item xs={3} >{'الى تاريخ'}{'/'}{loc.state.dateTo}</Grid>
            {/* <Grid item xs={3} >{'مركز تكلفة 1'}</Grid> */}
          </Grid>

        </Grid>

        <Grid item container>
          <Grid item xs={12} className={clasess.root}>
            <MaterialTable style={{ boxShadow: 'none' }}
              //style={{direction:'ltr'}}
              options={{
                showTitle: false,
                toolbar: false,
                sorting: false,
                selection: false,
                // ToolBar Search
                searchFieldAlignment: "left",
                searchFieldVariant: "outlined",
                // ToolBar header
                showSelectAllCheckbox: false,
                showTextRowsSelected: false,

                headerStyle: headerS,

                pageSize: 5,
                paginationType: "stepped",
                showFirstLastPageButtons: false,

                paging: false,
                // headerStyle: stElemnt.AF.header,
                cellStyle: stElemnt.AF.cell,
              }}


              localization={{ body: { emptyDataSourceMessage: <h1></h1>, }, }}

              columns={columns}
              data={loc.state.SalePurchVat}

            />
          </Grid>
        </Grid>
        <Grid item container >
            <Grid item xs={9} ></Grid>
            <Grid item xs={1} >{'2023'}</Grid>
            <Grid item xs={2} >{'2023'}</Grid>

        </Grid>


      </Grid>
  );

}


export default Body;