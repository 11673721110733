import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../../help/Api";

import Grid from "@material-ui/core/Grid";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import {
  useInvAF,
  useInvMain,
  useRptAF,
} from "../../../../St/Reviews/UseStyle";
import { useHistory, useLocation } from "react-router-dom";
import { sto } from "../../../../Context/InshData";

function HeaderA() {
  const cls = useRptAF();
  const clas = useInvAF();
  const clasm = useInvMain();
  const [state, setState] = useState({});
  const getInfo = async () => {
    axios
      .get(uri + "Foundation")
      .then((res) => {
        setState(res.data);
      })
      .catch((error) => {});
  };

  const loc = useLocation();
  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid
      container
      className={cls.root1}
      style={{ direction: "ltr", fontSize: 18 }}
    >
      <Grid item container style={{ border: "solid 1px #000" }}>
        <Grid item xs={5} className={cls.rootsub}>
          <Typography variant="h6" style={{ fontFamily: "Amiri-Regular" }}>
            {state?.nameE}
          </Typography>
          <Typography variant="h6" style={{ fontFamily: "Amiri-Regular" }}>
            {state?.FieldE}
          </Typography>
          <Typography variant="h6" style={{ fontFamily: "Amiri-Regular" }}>
            {"record number / " + state.bsn_no}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <ButtonBase>
            <img
              src={`data:image/jpeg;base64,${state.Logo}`}
              className={cls.logo}
              alt={state.nameA}
            />
          </ButtonBase>
        </Grid>
        <Grid item xs={5} className={cls.rootsub}>
          <Typography variant="h6" style={{ fontFamily: "Amiri-Regular" }}>
            {state.nameA}
          </Typography>
          <Typography variant="h6" style={{ fontFamily: "Amiri-Regular" }}>
            {state.FieldA}
          </Typography>
          <Typography variant="h6" style={{ fontFamily: "Amiri-Regular" }}>
            {"رقم السجل / " + state.bsn_no}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12} style={{ marginTop: 20 }}>
        <Grid item xs={3}></Grid>
        <Grid xs={6} item className={[clas.BdytitleHed]}>
          <Typography variant="h5" className={clasm.minclr}>
            {loc?.state?.title}
            &nbsp; &nbsp;
            {"من تاريخ"}
            &nbsp; &nbsp;
            {loc?.state?.dateFrom}
            &nbsp; &nbsp;
            {"إلى "}
            &nbsp; &nbsp;
            {loc?.state?.dateTo}
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </Grid>
  );
}
export default HeaderA;
