import React, { useState, useEffect } from 'react';

import useFetchObjectByPost from '../../../../../components/Hooks/useFetchObjectByPost';
import { sto } from '../../../../../Context/InshData';
import { uri } from '../../../../../help/Api';
import EmpSales from './EmpSales';
const defaultAutos ={
  Employees:[],
}
export default function EmpSalesCon() {

      const tb = {
                  wait:true,
                  Employees: true,
                  stor:sto,
                  parms:{},
                  autos:{Employees: true,}
            }

        const {object: AutoOptions, fetching:fetchAll} = useFetchObjectByPost(uri+'Res',tb); 

  useEffect(()=>{
    console.log("histort is ", AutoOptions);
    console.log("histort is 1 ", tb);
  },[AutoOptions])

  if(fetchAll || !AutoOptions?.Employees )
    return <></>

  return (
    <EmpSales {...{
      AutoOptions: AutoOptions?.Employees? AutoOptions: defaultAutos,
    } 
    }/>
  );




};