import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import {useStylesTF,useStylesTD} from '../../../../St/comps/UseStyle';

export default function GroupsForm(props){
    const { 
      object,
      groups,
      onChange,
      } = props;
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const onChangeData = (prop) => (event) =>{

      onChange(event, {name: prop, value: event.target.value});
    } 

    return(
        <React.Fragment>

        <Grid item xs={12}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"name"} label={" مجموعة الصنف "}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"name"} 
            value={object.name} 
            onChange={onChangeData('name')}
            />
        </Grid> 
                <Grid item xs={12}>
            <TextField fullWidth variant="outlined" size="small" 
            id={"nameEN"} label={"المجموعة (EN)"}
            className={clasTF.rootSale} 
            InputProps={{ className: clasTD.input }}
            type="text" 
            name={"nameEN"} 
            value={object.nameEN} 
            onChange={onChangeData('nameEN')}
            />
        </Grid> 
        <Grid>
        <RadioGroup
            row
            onChange={onChangeData('type')}
            value={object.type}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel value="main" control={<Radio />} label="رئيسية " />
            <FormControlLabel value="sub" control={<Radio />} label="فرعية " />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} >
        <Autocomplete // classes={clasAo}
            value={object.parent}
            onChange={(event, newValue) => { 
                onChange(null, {name:'parent', value: newValue});
             }}
            id="controllable-states-dem" size="small"
            options={groups}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
            //style={{  }}  // disabled={}
            disabled={object.type=='main'}
            disableClearable
            renderInput={(params) => <TextField id="valuePricePay" {...params} label={"المجموعة الأب "} 
            variant="outlined" size="small" 
                  />}
                />
        </Grid>
      </React.Fragment>
    )
    
}