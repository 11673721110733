
 import React, { useState,useEffect } from 'react';

 import axios from 'axios';
 import {uri, }  from '../../../help/Api';

 import Grid from '@material-ui/core/Grid';
 import ButtonBase from '@material-ui/core/ButtonBase';
 //import {useStylesHed} from '../../../St/comps/UseStyle';

 function Footer(){

   const locDinv = JSON.parse(localStorage.getItem('datainv')) || {};

   const [found, setFound] = useState({});
   const [inv, setInv] = useState({});


   const getFound = async () => {
     axios.get(uri+'Foundation').then(res => { setFound(res.data); }).catch(error => { });
   }

 const getInv = async () => {
    axios.get(uri+`InvForFatora?proc_id=${locDinv.proc_id}`).then(res => { 
        setInv(res.data); }).catch(error => { });
  }

 useEffect(() => {  
    getFound();
    getInv();
 }, []);

 const sty= {
     cntr:{textAlign:'center', },
     lft:{textAlign:'left',padingLeft:5, },
     rit:{textAlign:'right',padingRight:5, },
     dirR:{ direction:"rtl",color:'black' },
     dirL:{ direction:"ltr", },

 }
     return (
    <Grid item container style={sty.dirR}>
       <Grid item container style={sty.cntr} spacing={0}>
  
         <Grid item xs={12} >  
         <br/>
   <ButtonBase style={{border:'solid 1px'}}>  
  
        </ButtonBase>
       </Grid>

       </Grid>
    </Grid>
     );
   }
  export default Footer




// import React, { useState,useEffect } from 'react';
// import axios from 'axios';
// import {uri, }  from '../../../../help/Api';
// import Grid from '@material-ui/core/Grid';
// import { Typography } from '@material-ui/core';


// function Footer(){
  
//   const [state, setState] = useState({});
//   const getInfo = async () => {
//   axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
// }

// useEffect(() => { 
//   getInfo();
// }, []);

//     return (
//     <Grid className="fixed-footer bg">
//       <Grid item container style={{textAlign:'center',direction:'rtl',}}>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المستخدم</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المحاسب</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المراجع</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={2} md={2} sm={2} xs={2} >
//           <Typography className="lbl cb">المدير المالي</Typography> <Typography className="box b cw"></Typography>
//         </Grid>
//         <Grid lg={3} md={3} sm={3} xs={3} >
//           <Typography className="lbl cb">المدير العام</Typography> <Typography className="box b cw"></Typography>
//         </Grid>

//         <Grid lg={12} md={12} sm={12} xs={12} >
//           <Typography className="cw b">{state.Address}</Typography>
//         </Grid>

//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">هاتف:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Tel}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">مبايل:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Mobile}</Typography></Grid>
//         <Grid lg={1} md={1} sm={1} xs={1}><Typography className="lblInfo cb">فاكس:</Typography></Grid>
//         <Grid lg={3} md={3} sm={3} xs={3}><Typography className="lblInfo cb">{state.Fax}</Typography></Grid>
//       </Grid>        
//     </Grid>
//     );
//   }

// export default Footer


