import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import MainRBalanceForm from "./MainRBalance-form";
import MainRBalanceTable from "./MainRBalance-table";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import { useHistory } from "react-router-dom";
import { aMdefaultValues } from "../../../../services/accMasn-services";
import SelectTypesFilter from "./MainRBalance-typeFilter";
import { Skeleton } from "@material-ui/lab";
import MainRBalanceTotals from "./MainRBalance-totals";
import {
  useStylesHed,
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/comps/UseStyle";
import { insh as inshst } from "../../../../Context/InshSt";
import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

function Loader() {
  return (
    <Box sx={{ width: "100%" }}>
      <Skeleton />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
      <Skeleton animation={false} />
    </Box>
  );
}

export default function MainRBalance(props) {
  const { AutoOptions, CustomerOptions } = props;
  const [rptParams, setRptParams] = React.useState(aMdefaultValues);
  const classes = useStyles();
  const clashd = useStylesHed();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `AccountReports/rptAccMasn?dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch.id}
    &&center=${rptParams.center.id}
    &&finalAcc=${rptParams.type.finalAcc}
    &&type=${rptParams.type.name}
    &&level=${rptParams.level.id}
    &&acc_no=${""}
    &&chkPrev=${false}`,
      dependencyArray: [],
    },
  });
  useEffect(() => {
    console.log("hhhhhhhhhhhh is ", data);
  });
  const his = useHistory();
  const { t } = useTranslation(["forms", "translation"]);

  const printHandle = () => {
    const info = {
      ...rptParams,
      data: data,
      // totals: data,
      title: t(rptParams.type.label) + " خلال فترة",
      nDate: moment(new Date()).format("YYYY-MM-DD"),
    };

    his.push({ pathname: "/MainRBalanceRpint", state: info });
    window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid item container spacing={0} className={clashd.rootHd}>
        <Grid item xs={12}>
          <Chip
            size="small"
            className={clashd.ChipHdAccount}
            label={t("translation:acc-sys-rpt")}
            color="primary"
          />
        </Grid>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            paddingBottom: "0px",
            borderBottomColor: "#808080",
            borderBottomWidth: "5px",
            borderBottomStyle: "solid",
          }}
        >
          <SelectTypesFilter {...{ rptParams, setRptParams }} />
          <MainRBalanceForm
            {...{
              AutoOptions,
              CustomerOptions,
              rptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />

          <Grid
            container
            justifyContent="center"
            lg={12}
            md={12}
            sm={12}
            xs={12}
            spacing={1}
          >
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                size="large"
                style={{ ...inshst.account.iconBtn, borderRadius: 0 }}
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("forms:view")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                style={{ ...inshst.account.iconBtn, borderRadius: 0 }}
                onClick={printHandle}
              >
                {t("forms:print")}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                style={{ ...inshst.account.iconBtn, borderRadius: 0 }}
                onClick={() => {
                  his.push({ pathname: "/" });
                }}
              >
                {t("forms:exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>

        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container>
            {fetching ? (
              <Loader />
            ) : (
              <MainRBalanceTable {...{ data: data ? data : [], title: "" }} />
            )}
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          {!fetching && (
            <MainRBalanceTotals
              {...{
                data: data ? data : [],
                isMoragaa: rptParams.type.id == 3,
                level: rptParams.level.id,
              }}
            />
          )}
        </Container>
      </Typography>
    </div>
  );
}
