import React, { useState, useEffect } from 'react';

import { colors, Container, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import EmpSalesForm from './EmpSales-form';
import EmpSalesTable from './EmpSales-table';
import useFetchURL from '../../../../../components/Hooks/useFetchURL';
import { uri } from '../../../../../help/Api';
import TotalCard from '../../../../../components/TotalCard';
import moment from 'moment';
import BackdropScI from '../../../../../services/BackdropScI';
import BackdropSc from '../../../../../services/BackdropSc';
const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
  },
  itemW:{
    // display:'flex',
width:'90%'
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl:{
    marginTop: '17px',
    fontSize: '16px',
    padding: '-1px',
  },
}));

const SAdefaultValues = {
    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    dateTo: moment(new Date()).format('YYYY-MM-DD'),
    emp:{id:-1, name:'الكل'},
}

export default function EmpSales(props) {
  const {AutoOptions} = props;
  const [rptParams, setRptParams] = React.useState(SAdefaultValues);
  const classes = useStyles();

  const {data, fetching, fetchURL} = useFetchURL({...{
    url: uri+`RptSales/EmpSales?branch=${-1}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&emp=${rptParams.emp.id}`,
    dependencyArray:[rptParams]
  }});

  useEffect(()=>{
    console.log("rabi eshrah li sadri ", data);
    console.log("dfdsf is ", rptParams);
  },[data])

  const onChangeData =(propName)=>(event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData =(propName, newValue) =>{
    setRptParams({ ...rptParams, [propName]: newValue });
  }
  return (
        <div
          className={classes.root}>
            <Typography component="div" style={{  direction:'rtl' }} >
               <Container component={Paper} maxWidth={false}
                  style={{      
                  padding:'10px', 
                  borderBottomColor: colors.indigo[300],
                  borderBottomWidth:'1px',
                  borderBottomStyle:'solid'}}
               >
                <EmpSalesForm {...{AutoOptions, rptParams, onChangeData, onChangeAutoData}} />
               </Container>
              <Container maxWidth={true} style={{padding:'4px'}}>
                 <Grid container spacing={1} style={{paddingTop:'10px'}}>
                  <Grid item lg={2} md={2} sm={4} xs={6} >
                    <TotalCard {...{title:'الإجمالي', value:data.reduce((a,v) =>  a = a + v.sum_Price , 0 )}}/>
                  </Grid>
                  
                </Grid>
              </Container>
              <br/>
              <Container component={Paper}  maxWidth={false} 
              style={{      
              padding:'10px', 
            }}
              >
                <Grid container >
                  {
                    fetching? <BackdropSc />
                    :
                    <EmpSalesTable {...{data: data?.length> 0? data: [], }}/>

                  }
                </Grid>
              </Container>


            </Typography>

      </div>
  );




};