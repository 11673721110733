import React, { useEffect } from "react";
import { uri } from "../../help/Api";

import { sto } from "../../Context/InshData";
import useFetchObjectByPost from "../../components/Hooks/useFetchObjectByPost";
import Dashboard from "./Dashboard";
import LoadingPage from "../../services/LoadingPage";
import { colors, Grid, makeStyles, Typography } from "@material-ui/core";

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
} from "./components";
import moment from "moment";
import useFetchURL from "../../components/Hooks/useFetchURL";
import MostSoled from "./components/MostSoldProducts/MostSoled";
import { getLS } from "../../utils/useLS";
import Profile from "./components/Profile";
import { Main } from "../../POS/layouts";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    direction: "rtl",
  },
  back1: {
    backgroundColor: colors.red[100],
  },
  back2: {
    backgroundColor: colors.green[100],
  },
  back3: {
    backgroundColor: colors.indigo[100],
  },
}));
const UserDashboard = (props) => {
  const classes = useStyles();
  let today = new Date();
  let yasterday = moment(today.setDate(today.getDate())).format("YYYY-MM-DD");

  const { data: allData, fetching } = useFetchURL({
    ...{
      url:
        uri +
        `RptSales/dashboard?branch=${sto.branchi}
    &&dateFrom=${moment(new Date()).format("YYYY-MM-DD")}&&dateTo=${moment(
          new Date()
        ).format("YYYY-MM-DD")}`,
      dependencyArray: [],
    },
  });

  let pScr = getLS("premiScr", {});

  // if (!pScr.acc) return <div>لا يوجد صلاحية للوصول</div>;

  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Profile />

            <br />
          </Grid>
          <Grid container item spacing={1} lg={12} sm={12} md={12} xs={12}>
            <Grid item lg={4} sm={6} md={4} xs={12}>
              <Budget
                {...{
                  title: "مبيعات اليوم",
                  className: classes.back1,
                  total: allData ? allData.sales?.total.toFixed(2) : 0.0,
                  fetching,
                  curr: props.curr,
                }}
              />
            </Grid>
            <Grid item lg={4} sm={6} md={4} xs={12}>
              <TotalUsers
                {...{
                  className: classes.back2,
                  invCount: allData?.sales ? allData.sales.counts : 0,
                }}
              />
            </Grid>
            <Grid item lg={4} sm={6} md={4} xs={12}>
              <TasksProgress
                {...{
                  className: classes.back3,
                  value: allData.Revenues,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <MostSoled
                {...{
                  data: allData ? allData.mostFiveSoldProducts : {},
                  fetching,
                }}
              />
            </Grid>
            <Grid item lg={12} sm={12} md={5} xs={12}>
              <LatestSales
                {...{
                  data: allData ? allData.LatestSevenDaysSale : {},
                  fetching,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12} sm={12}></Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export const POSDashboardCon = () => {
  const tb = {
    wait: true,
    // costcenters: true,
    defaultOmla: true,
    stor: sto,
    parms: {},
  };
  const { object: AutoOptions, fetching: fetchAll } = useFetchObjectByPost(
    uri + "Rept",
    tb
  );

  useEffect(() => {
    console.log("hi edrees", sto);
  });
  if (fetchAll || !AutoOptions?.defaultOmla) return <LoadingPage />;

  return (
    <Main>
      <UserDashboard
        {...{
          curr: AutoOptions?.defaultOmla?.symbol,
        }}
      />
      <br />
    </Main>
  );
};

export default POSDashboardCon;
