import React, { useState } from 'react';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from '@material-ui/lab';
import Button from "@material-ui/core/Button";

import {uri} from '../../../../../help/Api';
import SaveeIcon from '@material-ui/icons/Save';

import { useStyles, } from './StoreTransferSub-style';
import { useEffect } from 'react';
import { subTaswyaValidateSchema } from '../../../../../services/sub-taswya-service';
import toast from 'react-hot-toast';

export default function StoreTransferSubForm(props){

    const { 
      addRow,
      editing,
      currentRow,
      setCurrentRow,
      updateRow,
      cancelUpdate,
      data,
      ching: fetchingCurr,} = props;
      
    const classes = useStyles();

    const [submitting, setSubmitting] = useState(false);
    const [currentCurrency, setCurrentCurrncy] = React.useState({})

    const onChangeData =(event) =>{
      setCurrentRow({...currentRow,
      val2: event.target.value,
      diff: event.target.value - currentRow.Qtys,
    })
    } 

    const submit=()=>{
      subTaswyaValidateSchema.validate(currentRow, { abortEarly: false })
      .then(function (valid) {
        if(currentRow.val2 > currentRow.Qtys)
        {
          toast.error("الكمية المراد تحويلها اكبر من المتوفره .. برجاء تحديد عدد اقل او يساوي المتوفر		")
        }else{
          if(editing)
            updateRow(currentRow.id, currentRow)
          else
            addRow(currentRow);
          
          setCurrentCurrncy({});
        }
        
      }
      ).catch((error)=>{
        toast.error(error.errors[0]);
      })
    }

    return(
        <Grid item spacing={1} container>
          
         <Grid className={classes.textField} item lg={1} md={2} sm={2} xs={4}>
          <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label="الباركود "
            name="currentRow.barcode" InputLabelProps={{ shrink: true }}
            error={submitting && currentRow.barcode ===''}
            value={currentRow.barcode}
          />
        </Grid>
        <Grid className={classes.textField} item lg={2} md={2} sm={3} xs={4}>
            <Autocomplete
            loading={fetchingCurr}
            value={currentRow}
            onChange={(event, newValue) => {
                // oncurrnecyChange(newValue);
                setCurrentRow(newValue);
            }}
            id="controllable-states-demo" size="small"
            disableClearable
            options={data || []}
            getOptionLabel={(option) => option?.item?.symbol || ' '}
            renderInput={(params) => <TextField  {...params} label="  اسم الصنف" variant="outlined" size="small" />}
          />
        </Grid>
        <Grid className={classes.textField} item lg={1} md={2} sm={3} xs={4}>
          <Autocomplete  
              value={currentRow.mUnit}
            onChange={(event, newValue) => { 
              setCurrentRow({...currentRow, unit: {id: newValue.id, name: newValue.name}})
            }}
            id="combo-box-demo" size="small"
            disabled={true}
            options={[]} 
            getOptionLabel={(option) => option.name || ' '}
            disableClearable
            renderInput={(params) => <TextField  {...params} defaultValue="null" InputLabelProps={{shrink: true}}  label=" الوحدة" variant="outlined" />} 
            // InputLabelProps={{className:claslbl.clrSale}}
          />
          
        </Grid>
                <Grid className={classes.textField} item lg={2} md={2} sm={3} xs={4}>
          <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label=" الكمية  "
            name="currentRow.val2" value={currentRow.val2} 
            onChange={onChangeData}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid className={classes.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField type="text" fullWidth variant="outlined"
            size="small" id="stay" label=" الكمية المتوفرة  "
            name="nameE" value={currentRow.Qtys} InputLabelProps={{ shrink: true }}
            disabled={true}
          />
        </Grid>

      

        <Grid className={classes.textField} item lg={2} md={2} sm={2} xs={3}>
        <Button className={classes.buttonss}   style={{direction:"ltr",backgroundColor:'blue'}}   variant="contained" color="primary" fontSize="large" size="medium"
            onClick={submit} startIcon={<SaveeIcon/>}>{editing? 'تعديل':'تنزيل'}    </Button>
        </Grid>
        <Grid className={classes.textField} item lg={2} md={2} sm={2} xs={2}>
       {
        editing &&  <Button className={classes.buttonss}   style={{direction:"ltr",backgroundColor:'blue'}}   variant="contained" color="primary" fontSize="large" size="medium"
            onClick={()=>{ 
              cancelUpdate();
              }} >{'إلغاء '} </Button>
       }
        </Grid>

      </Grid>
    )
    
}