import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../help/Api";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
//import {useStylesHed} from '../../../St/comps/UseStyle';
import { useInvCa } from "../../../St/Reviews/UseStyle";
import { sto } from "../../../Context/InshData";

function Footer() {
  const clas = useInvCa();
  //const clashd = useStylesHed();
  //  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};

  const [found, setFound] = useState({});
  const [inv, setInv] = useState({});

  //  const inshInfoInv = {
  //   tot:'الاجمالي', totBefurDisc:'الإجمالي قبل الخصم',
  //   totDisc:'اجمالي الخصم', totAfterDisc:'الإجمالي بعد الخصم',
  //   totBefurAmount:'المجموع قبل الضريبة',totAmount:'إجمالي الضريبة',
  //   tax:'ضريبة القيمة المضافة',shaml:'الإجمالي يشمل القيمة المضافة',
  //   cash:'المدفوع',agel:'المتبقي',
  //   note:'البيان', tell:'رقم الهاتف',phone:'الجوال',
  //  }

  //  const [infoInv, setinfoInv] = useState(inshInfoInv);

  const getFound = async () => {
    axios
      .get(uri + "Foundation")
      .then((res) => {
        setFound(res.data);
      })
      .catch((error) => {});
  };

  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locDinv.proc_id}`)
      .then((res) => {
        setInv(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getFound();
    getInv();
  }, []);

  const sty = {
    cntr: { textAlign: "center" },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: { direction: "rtl", color: "black" },
    dirL: { direction: "ltr" },
  };

  return (
    // <span style={{color:'black'}}>
    <Grid item container style={sty.dirR}>
      <Grid item container style={sty.cntr} spacing={0}>
        <br />
        <Grid xs={12}>
          <Typography
            className={[clas.contntSub]}
            style={{
              margin: "0.5rem 0.1rem 0.5rem 0.1rem",
              borderRadius: 5,
              color: "#5e5e5e",
              background: "#ffffee",
              padding: "0.5rem 0.5rem 0.5rem 0.5rem",
            }}
          >
            {found.Address}
          </Typography>
        </Grid>

        {inv.tax > 0 && (
          <Grid item xs={12}>
            <ButtonBase
              style={{
                border: "solid 0.1px",
                borderRadius: "3",
                margin: 0,
                padding: 0,
              }}
            >
              <img
                src={`data:image/jpeg;base64,${inv.qr_image}`}
                style={{ height: "57mm", width: "57mm" }}
                alt={found.nameA}
              />
            </ButtonBase>
          </Grid>
        )}

        <Grid item xs={12}>
          {"_____"}
        </Grid>
      </Grid>
    </Grid>
    // </span>
  );
}
export default Footer;
