import { Container } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import useFetchURL from "../../../components/Hooks/useFetchURL";
import Swipeable from "../../../Fronts/Main/Swipeable";
import { uri } from "../../../help/Api";
import LoadingPage from "../../../services/LoadingPage";
import ConfirmAdmin from "./user-confirm";
import UserPermissions from "./user-permissions";

function UserPermissionsStart() {
  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url: uri + "Users",
    },
  });

  if (fetching || !data || !data[0]) return <LoadingPage />;

  return (
    <UserPermissions users={data} gData={{ user: data[0], Form_id: 10004 }} />
  );
}

export default function UserPermissionsCon() {
  const [confirmed, setConfirmed] = useState(false);

  if (!confirmed)
    return (
      <>
        <Container style={{ direction: "rtl" }}>
          <Toaster />
          <ConfirmAdmin
            keepMounted
            confirm={(pass) => {
              if (!pass) toast.error("لا يوجد لديك صلاحية للوصول");
              setConfirmed(pass);
            }}
          />
        </Container>
        <Swipeable />
      </>
    );

  return <UserPermissionsStart />;
}
