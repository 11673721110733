 import React, { useState,useEffect } from 'react';
 import axios from 'axios';
 import {uri, }  from '../../../help/Api';
 import Grid from '@material-ui/core/Grid';
 import Typography from '@material-ui/core/Typography';
 import ButtonBase from '@material-ui/core/ButtonBase';
 import {useInvAF,useInvMain} from '../../../St/Reviews/UseStyle';
 import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';


 const useStyles = makeStyles((theme) => ({
  root: {

    border: 'solid 1px',
    borderRadius: '8px',
    // width: '100%',
    width: '22cm',
    marginLeft: '0.3cm',
    // marginRight: '0.2cm',
    // padding: '15px',
    // zIndex: 1,
  },
}));
 function Header(){
  const clasm = useInvMain();
  const clas = useInvAF();
  const clasess = useStyles();

  // const clasH = useInvHeadrAF();

  
  const location = useLocation();
  
   const [info, setInfo] = useState({});
   const getInfo = async () => {
    const tb = {GetFoundation:true, parms:{ iFoundid:1,},
    autos:{}, }
     axios.post(`${uri}Res`,tb).then(res => { setInfo(res.data.Found); }).catch(error => { });
   }
const styf = {fontWeight:'500'}
const styfC = {fontWeight:'500',color:'#9a3f47'}
 useEffect(() => { getInfo(); }, []);

     return (
     <Grid item container className={clasess.root} style={{padding:5,fontWeight:'bold'}}>
       <Grid item xs={5}>
       <Typography style={styf}>{info.nameE}</Typography><Typography style={styf}>{info.FieldE}</Typography>
         <Typography style={styfC}>{'record number / '+info.bsn_no}</Typography> <Typography style={styfC}>{info.Disc4_header_en}</Typography>
         <Typography style={styf}>{'Vat number '+info.tax_no}</Typography>
        </Grid>

        <Grid item xs={3} className={clasm.mincntr}>
         <Typography >
         <ButtonBase> <img src={`data:image/jpeg;base64,${info.Logo}`} className={clas.Hedlogo} alt={info.nameA}/> </ButtonBase>
         </Typography>
         <Typography style={styf}><b>{` التقرير الضريبي ${location.state.title}`}</b></Typography>

        </Grid>

        <Grid item xs={4} className={clasm.minrit}>
         <Typography style={styf}>{info.nameA}</Typography> <Typography style={styf}>{info.FieldA}</Typography>
         <Typography style={styfC}>{'رقم السجل / '+info.bsn_no}</Typography> <Typography style={styfC}>{info.Address}</Typography>
         <Typography style={styf}>{'الرقم الضريبي '+info.tax_no}</Typography>
        </Grid>

      </Grid>
     );
   }
  export default Header


