import React, { useState, useEffect } from "react";
import BackdropScI from "../../../services/BackdropScI";
import axios from "axios";
import { uri } from "../../../help/Api";
import moment from "moment";
import Grid from "@material-ui/core/Grid";

// Ust
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";

import {
  Chip,
  colors,
  Container,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import toWords from "../../../utils/toWords";

const useStyles = makeStyles((theme) => ({
  minclr: {
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    textAlign: "center",
  },
  txt: {
    fontFamily: "Amiri-Regular",
    padding: 5,
  },
  border: {
    border: "solid 0.1px",
    padding: 5,
  },
  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",
    marginTop: 10,
    // minHeight: 350,
  },

  BdymtTabl1: {
    border: "solid 0.1px",
  },
  BdytotTitle: {
    border: "solid 0.1px black",
    borderRadius: "6px",
    textAlign: "center",
    color: "#be151f",
    fontWeight: "bold",
    margin: 2,
  },
  totalItem: {
    padding: 2,
    border: "solid 0px",

    fontFamily: "Amiri-Regular",
  },
}));

const ReceiptBody = (props) => {
  const clas = useInvAF();
  const clasm = useInvMain();
  const calssBody = useStyles();
  // const clasB = useInvBodyAF();

  const locinf = JSON.parse(localStorage.getItem("iInfos")) || {};

  return (
    <Container>
      <Grid
        item
        container
        className={clas.Bdyroot}
        style={{ direction: "rtl", paddingBottom: "5rem" }}
      >
        <Grid container item xs={12} style={{ paddingTop: "0.5rem" }}>
          <Grid
            xs={3}
            item
            container
            style={{
              paddingTop: 10,
              direction: "rtl",
              // color: secondColor,
              // backgroundColor: colors.green[50],
              fontWeight: "bold",
            }}
          >
            <Grid item xs={6} className={calssBody.txt}>
              التاريخ
            </Grid>
            <Grid item xs={6}>
              <Typography>
                {moment(locinf.Date).format("YYYY/MM/DD")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid container item xs={4}>
            <Grid xs={2} item></Grid>
            <Grid
              xs={8}
              item
              // className={[clas.BdytitleHed]}
              style={{
                backgroundColor: colors.yellow[100],
                textAlign: "center",
                border: "solid 1px",
              }}
            >
              <Typography
                variant="h6"
                className={clasm.minclr}
                // style={{ backgroundColor: colors.orange[50] }}
              >
                {"سند اشعار دائن"}
              </Typography>
            </Grid>
            <Grid xs={2} item></Grid>
          </Grid>

          <Grid item xs={1}></Grid>

          <Grid
            xs={3}
            item
            container
            style={{
              paddingTop: 10,
              direction: "rtl",
              // color: secondColor,
              // backgroundColor: colors.green[50],
              fontWeight: "bold",
            }}
          >
            <Grid item xs={6} className={calssBody.txt}>
              رقم السند
            </Grid>
            <Grid item xs={6}>
              <Typography>{locinf.data.id}</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            sx={12}
            className={calssBody.border}
            style={{ fontWeight: "bold", textAlign: "center", marginTop: 15 }}
            // className={calssBody.border}
          >
            <Grid
              item
              xs={12}
              className={calssBody.BdymtTabl1}
              container
              style={{ fontWeight: "bold", textAlign: "center" }}
            >
              <Grid item xs={1} className={calssBody.txt}>
                {"السيد  "}
              </Grid>
              <Divider
                orientation="vertical"
                style={{ width: 1.5, backgroundColor: "#000" }}
              />
              <Grid item xs={7} className={calssBody.txt}>
                {locinf.auto?.custName}
              </Grid>
              <Divider
                orientation="vertical"
                style={{ width: 1.5, backgroundColor: "#000" }}
              />
              <Grid item xs={2} className={calssBody.txt}>
                {"رقم الحساب  "}
              </Grid>
              <Divider
                orientation="vertical"
                style={{ width: 1.5, backgroundColor: "#000" }}
              />
              <Grid item xs={1} className={calssBody.txt}>
                {locinf?.data?.customer?.acc_no}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ height: 2, backgroundColor: "#000" }} />
            </Grid>
            <Grid
              item
              xs={12}
              className={calssBody.txt}
              style={{ textAlign: "center", paddingTop: 10 }}
            >
              {"نود إشعاركم أننا قيدنا لحسابكم لدينا حسب التفاصيل التالية"}
            </Grid>
            <Grid
              item
              xs={12}
              container
              className={calssBody.BdymtTabl1}
              style={{ fontWeight: "bold", marginInline: 50 }}
            >
              <Grid
                container
                item
                xs={12}
                style={{ backgroundColor: colors.green[50] }}
              >
                <Grid item xs={5} className={calssBody.txt}>
                  {"عملة الحساب"}
                </Grid>
                <Divider
                  orientation="vertical"
                  style={{ width: 1.5, backgroundColor: "#000" }}
                />
                <Grid item xs={5} className={calssBody.txt}>
                  {"مبلغ الحساب"}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ height: 2, backgroundColor: "#000" }} />
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={5} className={calssBody.txt}>
                  {locinf?.auto?.omlaName}
                </Grid>
                <Divider
                  orientation="vertical"
                  style={{ width: 1.5, backgroundColor: "#000" }}
                />
                <Grid item xs={5} className={calssBody.txt}>
                  {"#"}
                  {locinf.data?.val}
                  {"#"}
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              className={calssBody.border}
              style={{
                textAlign: "center",
                fontFamily: "Amiri-Regular",
                paddingTop: 10,
                marginTop: 5,
                backgroundColor: colors.green[50],
              }}
            >
              {toWords(locinf.data.val)}

              {"  فقط لا غير  "}
            </Grid>
            <Grid
              item
              container
              xs={12}
              className={calssBody.border}
              // spacing={2}
              style={{
                textAlign: "right",
                marginTop: 5,
                // backgroundColor: colors.green[50],
              }}
            >
              <Grid item xs={2} className={calssBody.txt}>
                {"وذلك مقــابل" + ": "}
              </Grid>
              <Grid item xs={9} className={calssBody.txt}>
                {locinf.data.notes}
              </Grid>
              <Grid
                item
                xs={12}
                container
                className={calssBody.txt}
                style={{ fontWeight: "bold", paddingTop: 5, paddingBottom: 5 }}
              >
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  {"الصندوق / البنك" + ": "}
                </Grid>
                <Grid item xs={10}>
                  {locinf.auto.stockName}
                </Grid>
              </Grid>
              {locinf?.data.type != 1 ? (
                <Grid
                  item
                  container
                  xs={12}
                  style={{
                    padding: 15,
                    textAlign: "right",
                    fontWeight: "bold",
                  }}
                  // className={calssBody.BdymtTabl1}
                >
                  <Grid item container xs={4}>
                    <Grid
                      item
                      xs={4}
                      style={{ textAlign: "center" }}
                      className={calssBody.txt}
                    >
                      {"شيك رقم :"}
                    </Grid>
                    <Grid item xs={8}>
                      {locinf?.data?.check_no}
                    </Grid>
                  </Grid>
                  <Grid item container xs={4}>
                    <Grid
                      item
                      xs={4}
                      style={{ textAlign: "center" }}
                      className={calssBody.txt}
                    >
                      {"تاريخ الشيك"}
                    </Grid>
                    <Grid item xs={8}>
                      {moment(locinf?.data?.check_date).format("YYYY/MM/DD")}
                    </Grid>
                  </Grid>
                  <Grid item xs={4} container>
                    <Grid
                      item
                      xs={4}
                      style={{ textAlign: "center" }}
                      className={calssBody.txt}
                    >
                      {"على بنك :"}
                    </Grid>
                    <Grid item xs={8}>
                      {locinf.data?.checkbank}
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Grid item container>
            <Grid item xs={2}></Grid>
          </Grid>
        </Grid>
        <Grid item container className={clas.BdytotSub}>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{ paddingTop: 50, fontWeight: "bold", textAlign: "center" }}
          >
            <Grid item xs={6}>
              {" "}
              {"محرر السند"}
            </Grid>
            <Grid item xs={6}>
              {" المستلم" + "     " + " Receivr "}
            </Grid>
            <Grid item xs={6}>
              {locinf.auto?.empName}
            </Grid>
            <Grid item xs={6}>
              ____________________
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReceiptBody;
