import React, { Suspense } from "react";
import "./App.css";
import CssBaseline from "@material-ui/core/CssBaseline";

import RevCasher from "./Reviews/Invs/CasherReadyDisigen/RevCasher"; /* CasherReadyDisigen */
import ReviewAF from "./Reviews/Invs/AFReadyDisigen/RevAF"; /* AFReadyDisigen */
import Foundation from "./comps/bascDatas/Foundation";

import ReviewMQ from "./Reviews/ReviewMQ";
import ReviewMultiSarf from "./Reviews/MultiSarf/Review";

import Bigen from "./Start/Bigen";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";

import "./i18n";

// Settings
import SettingsPrinter from "./comps/Settings/SettingsPrinter";
import PrintCasher from "./comps/Settings/PrintCasher";
import Settings from "./comps/Settings/Settings";
import ReData from "./comps/Settings/ReData";

import { createTheme } from "@material-ui/core/styles";

import { MuiThemeProvider } from "@material-ui/core/styles";

import GlobalContext from "./Context/GlobalContext";
import useFetchURL from "./components/Hooks/useFetchURL";
import { sto } from "./Context/InshData";
import { uri } from "./help/Api";
import useFetchObjectByPost from "./components/Hooks/useFetchObjectByPost";

import { useState } from "react";
import { getLS } from "./utils/useLS";
import Routes from "./POS/Routes";
import MainRoutes from "./MainRoutes";
import UsersPermissionsCont from "./comps/users/UserPermissions/UserPermissionsCont";

const tb = {
  groups: true,
  costcenters: true,
  parms: {},
  autos: { groups: true, costcenters: true, safes: true },
};

// import Reviews from './Foateer/Sales/Eqfal/ReviewEqfalEveryDay';
const theme = createTheme({
  typography: {
    fontFamily: [
      // 'Tajawal',
      "Noto Kufi Arabic",
      "Tajawal",

      // 'Almarai',
    ].join(","),

    fontSize: ["12"].join(","),
  },
});
function NestedRoute() {
  let { path, url } = useRouteMatch();
  return (
    <Switch>
      <Router>
        <Route exact path={`${path}/pos`} component={UsersPermissionsCont} />
      </Router>
    </Switch>
  );
}
function App() {
  const { data: safes, fetching: fetchingSafes } = useFetchURL({
    ...{
      url: uri + "Auto" + "/safe?id=" + sto.empi + "&&b=" + sto.branchi,
    },
  });
  const url = uri + "Res";
  const { object, fetching } = useFetchObjectByPost(url, tb);
  let pScr = getLS("premiScr", {});
  const [salePointOnly, setSalePointOnly] = useState(
    pScr?.salePointOnly ?? false
  );
  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{
          costCenters: object.costcenters,
          groups: object.groups,
          safes: safes,
          omlat: object.omlat,
        }}
      >
        <CssBaseline />
        <Suspense fallback={null}>
          <MuiThemeProvider theme={theme}>
            <Router>{salePointOnly ? <Routes /> : <MainRoutes />}</Router>
          </MuiThemeProvider>
        </Suspense>
      </GlobalContext.Provider>
    </React.Fragment>
  );
}

export default App;
