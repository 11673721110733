import React, { useState, useEffect } from "react";

import moment from "moment";
import Grid from "@material-ui/core/Grid";

// Ust
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";
// csG

import {
  Chip,
  colors,
  Container,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import toWords from "../../../utils/toWords";

const useStyles = makeStyles((theme) => ({
  minclr: {
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    textAlign: "center",
  },
  txt: {
    fontFamily: "Amiri-Regular",
  },

  BdymtTabl: {
    border: "solid 0.1px",
    borderRadius: "6px",
    marginTop: 10,
    // minHeight: 350,
  },

  BdymtTabl1: {
    border: "solid 0.1px",
    // borderRadius: "1px",
    // margin: 10,
    // minHeight: 350,
  },
  BdytotTitle: {
    border: "solid 0.1px black",
    borderRadius: "6px",
    textAlign: "center",
    color: "#be151f",
    fontWeight: "bold",
    margin: 2,
  },
  totalItem: {
    padding: 2,
    border: "solid 0px",

    fontFamily: "Amiri-Regular",
  },
}));

const GeneralBody = () => {
  const clas = useInvAF();
  const clasm = useInvMain();
  const calssBody = useStyles();
  // const clasB = useInvBodyAF();

  const locinf = JSON.parse(localStorage.getItem("iInfos")) || {};

  const secondColor = "#be151f";

  useEffect(() => {
    // alert('getInfo')
    // console.log(sta.tot)\
    console.log("child ", locinf);
  }, []);

  return (
    <Container>
      <Grid
        item
        container
        className={clas.Bdyroot}
        style={{ direction: "rtl", paddingBottom: "5rem" }}
      >
        <Grid container item xs={12} style={{ paddingTop: "1rem" }}>
          <Grid xs={3} item container>
            <Grid item xs={6} className={calssBody.txt}>
              التاريخ
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {moment(locinf.Date).format("YYYY/MM/DD")}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ paddingTop: 10 }}
              className={calssBody.txt}
            >
              طريقة الدفع
            </Grid>
            <Grid item xs={6}>
              <Chip
                label={locinf?.auto?.type}
                style={{
                  backgroundColor: colors.green[50],
                  paddingLeft: "10px",
                  paddingRight: "10px",
                  color: secondColor,
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
                className={calssBody.txt}
              />
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid xs={4} item className={[clas.BdytitleHed]}>
            <Typography variant="h5" className={clasm.minclr}>
              {locinf.title}
              {/* {"سند صرف - خارجي"} */}
              <Divider
                style={{
                  background: secondColor,
                  height: 1,
                  margin: 5,
                  marginInline: 50,
                }}
              />
            </Typography>
            <Typography className={clasm.minclr}>{locinf?.subTitle}</Typography>
          </Grid>
          <Grid item xs={1}></Grid>

          <Grid
            xs={3}
            item
            container
            style={{
              paddingTop: 10,
              padding: 10,
              direction: "rtl",
              // color: "secondColor",
              backgroundColor: colors.green[50],
              fontWeight: "bold",
            }}
          >
            <Grid item xs={6} className={calssBody.txt}>
              رقم السند
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {locinf.data.id}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{ background: colors.green[300], height: 1, margin: 5 }}
              />
            </Grid>
            <Grid item xs={6} className={calssBody.txt}>
              المبلغ
            </Grid>
            <Grid item xs={6}>
              <Typography className={calssBody.minclr}>
                {"#"}
                {locinf.data.val}
                {"#"}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            container
            style={{ padding: 15 }}
            className={calssBody.BdymtTabl}
          >
            <Grid
              item
              xs={10}
              className={calssBody.txt}
              container
              style={{ fontWeight: "bold" }}
            >
              <Grid item xs={2}>
                {locinf.label + ": "}
              </Grid>
              <Grid item xs={10}>
                {locinf.auto?.custName}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={10}
              container
              className={calssBody.txt}
              style={{ fontWeight: "bold", paddingTop: 5 }}
            >
              <Grid item xs={2}>
                {"مبلغ وقدرة" + ": "}
              </Grid>
              <Grid item xs={10}>
                {toWords(locinf.data.val)}

                {"  فقط لا غير  "}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={10}
              container
              className={calssBody.txt}
              style={{ fontWeight: "bold", paddingTop: 5 }}
            >
              <Grid item xs={2}>
                {"وذلك مقــابل" + ": "}
              </Grid>
              <Grid item xs={10}>
                {locinf.data.notes}
              </Grid>
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={12}
              container
              className={calssBody.txt}
              style={{ fontWeight: "bold", paddingTop: 15, paddingBottom: 5 }}
            >
              <Grid item xs={2} style={{ textAlign: "center" }}>
                {"الصندوق / البنك" + ": "}
              </Grid>
              <Grid item xs={10}>
                {locinf.auto.stockName}
              </Grid>
            </Grid>
            {locinf?.data.type != 1 ? (
              <Grid
                item
                container
                xs={12}
                style={{ padding: 15, textAlign: "right", fontWeight: "bold" }}
                className={calssBody.BdymtTabl1}
              >
                <Grid item container xs={4}>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    {"شيك رقم :"}
                  </Grid>
                  <Grid item xs={8}>
                    {locinf?.data?.check_no}
                  </Grid>
                </Grid>
                <Grid item container xs={4}>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    {"تاريخ الشيك"}
                  </Grid>
                  <Grid item xs={8}>
                    {moment(locinf?.data?.check_date).format("YYYY/MM/DD")}
                  </Grid>
                </Grid>
                <Grid item xs={4} container>
                  <Grid item xs={4} style={{ textAlign: "center" }}>
                    {"على بنك :"}
                  </Grid>
                  <Grid item xs={8}>
                    {locinf.data?.checkbank}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid item container className={clas.BdytotSub}>
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{ paddingTop: 50, fontWeight: "bold", textAlign: "center" }}
          >
            <Grid item xs={6}>
              {" "}
              {"محرر السند"}
            </Grid>
            <Grid item xs={6}>
              {" المستلم" + "     " + " Receivr "}
            </Grid>
            <Grid item xs={6}>
              {locinf.auto?.empName}
            </Grid>
            <Grid item xs={6}>
              ____________________
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default GeneralBody;
