import React,{Fragment} from 'react';
import MaterialTable,  { MTableToolbar } from "material-table";
import {cellS,headerS,} from '../../../../St/comps/cStyle'

export default function SafeGrdUnitsTable(props){

    const {data} = props;

 var columns = [
    {title: "رقم الصنف", field: "currency_form",},
    {title: "الصنف", field: "Name_Item",},
    {title: "الرصيد", field: "dec_Qtys",},
    {title: "متوسط سعر التكلفة", field: "cost",},
    {title: "اجمالي التكلفة", field: "Sum_Cost",},
    {title: "سعر البيع", field: "sale_price",},
    {title: "اجمالي سعر البيع", field: "Sum_sale_price",}
  ];


    return(
        <MaterialTable style={{boxShadow:'none'}}
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,
              maxBodyHeight: '70vh',
              minBodyHeight: '70vh',

             }}
       
             localization={{
               
              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
             columns={columns}
             data={data}
            //  icons={tableIcons}
             
             onRowClick={(event, rowData) => {
              event.stopPropagation();
            }}/>
    )
}