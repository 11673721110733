import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { colors } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
      table: {
        minWidth: 650,
      },
}));

export default function EmpSalesTable(props){

    const {data} = props;
    const classes = useStyles();
    return(
      <TableContainer component={Paper} >
       <Table className={classes.table} size="small" aria-label="a dense table">
         <TableHead>
           <TableRow style={{backgroundColor: colors.indigo[300], color:'#fff'}}>
             <TableCell style={{color:'#fff'}} align="center">نوع الحركة</TableCell>
             <TableCell style={{color:'#fff'}} align="center">التاريخ</TableCell>
             <TableCell style={{color:'#fff'}} align="center">رقم الفاتورة</TableCell>
             <TableCell style={{color:'#fff'}} align="center">الصنف</TableCell>
             <TableCell style={{color:'#fff'}} align="center">الكمية</TableCell>
             <TableCell style={{color:'#fff'}} align="center"> السعر</TableCell>
             <TableCell style={{color:'#fff'}} align="center"> الإجمالي</TableCell>
             <TableCell  align="center"></TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
           {data.map((row) => (
             <TableRow key={row.id}>
               <TableCell align="center">{row.Doc_Name}</TableCell>
               <TableCell align="center">{row.date}</TableCell>
               <TableCell align="center">{row.proc_id}</TableCell>
               <TableCell align="center">{row.symbol}</TableCell>
               <TableCell align="center">{row.val}</TableCell>
               <TableCell align="center">{row.exchange_price}</TableCell>
               <TableCell align="center">{row.sum_Price}</TableCell>
               <TableCell align="center">{}</TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>
    )
}