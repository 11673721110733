import React, { useState,useEffect } from 'react';

import axios from 'axios';
import {uri, }  from '../../../../help/Api';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
import { useLocation } from 'react-router-dom';

 function HeaderA () {
  const cls = useRptAF();
  const [state, setState] = useState({});
  const location = useLocation();
  const getInfo = async () => {
  axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
}

useEffect(() => {  
  getInfo();
  console.log(location);
}, []);

return (

      <Grid item container className={cls.root}>
        <Grid item xs={5} className={cls.rootsub}>
          <Typography>{state.nameE}</Typography>
          <Typography>{state.FieldE}</Typography>
        </Grid>
        <Grid item xs={2} >
         <ButtonBase>
         <img src={`data:image/jpeg;base64,${state.Logo}`} className={cls.logo} alt={state.nameA}/> 
         </ButtonBase>
        </Grid>
        <Grid item xs={5} className={cls.rootsub}>
          <Typography>{state.nameA}</Typography>
          <Typography>{state.FieldA}</Typography>
        </Grid>

        {/* <Grid item xs={12} className={[cls.title,cls.titleM]}> */}
        <Grid item xs={12} >
         <Typography  > <b> </b> </Typography>
        </Grid>

      <Grid item container className={[cls.title,cls.titleS]}>

        <Grid item container >
          <Grid item xs={2}> رقم الامر</Grid>
          <Grid item xs={2}> {location.state.no}</Grid>
          <Grid item xs={2}>/</Grid>
          <Grid item xs={2}>{'التاريخ'}</Grid>
          <Grid item xs={3}> {location.state.date} </Grid>
        </Grid>

        <Grid item container >
        <Grid item xs={2}>{'المخزن'}</Grid>
          <Grid item xs={2} className={cls.tlR}>{location.state.safe.name}</Grid>
          <Grid item xs={2} >/</Grid>
          <Grid item xs={2} >{'مركز الكلفة'}</Grid>
          <Grid item xs={3} >{location.state.center}</Grid>
        </Grid>

        <Grid item container >
        <Grid item xs={2}>{'البيان'}</Grid>
          <Grid item xs={4} >{location.state.notes}</Grid>
        </Grid>

      </Grid>

    </Grid>
  );

   // return (


      // <Grid item container justifyContent='center' spacing={0}>



      //   <Grid item container className="">

      //   </Grid>

      //   <Grid item container className="">
     //  </Grid>
     
      //   <Grid item container className="">
     //  </Grid>
      // </Grid>

      // </Grid>
   // );

  }
export default HeaderA;


