import React,{useEffect, useState} from 'react';
import BackdropSc from '../../../services/BackdropSc';
import axios from 'axios';
import {uri}  from '../../../help/Api';
import {insh,sto} from '../../../Context/InshData';
import {insh as inshst} from '../../../Context/InshSt';
import {col} from '../../../Context/inshMt';
import {elmnts,elmntall} from '../../../Context/Elmnts';
import {getLS}  from '../../../utils/useLS';
import {txtA} from '../../../Label/Lbl';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import Typography from '@material-ui/core/Typography';

import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import dataF from '../../../Server/dataF.json';

// cuG
import {useStyleI} from '../st/UseStyle';
// csG
import {style} from '../st/cStyle'

export default function Icons() {
  const classes = useStyleI();
  let his = useHistory();
  const timer = React.useRef();

  const [prmiScr, setPermiScr] = useState(insh.prmiScr);
  const [showSc, setShowSc] = useState(true);

  const getUPrmi = () => {
    const loc = getLS('premiScr',{});
    setPermiScr(loc);
  }

  const loader = () => 
  { timer.current = window.setTimeout(() => { setShowSc(false); }, 600); }
  useEffect( () => {
    loader();
    getUPrmi();
    return () => { clearTimeout(timer.current); };
  }, []);


  //sa

  const clickInv = (val) => {

    val?his.push({
      pathname: '/item',
      state: { 
        idata:insh.items,
            front:{title:txtA.bascData.items,col:col.items,show:{rightDown:false,}},
            setting:
            {tb:{wait:true,other:true,groups:true,Branches:true,tax_groups:true,
                 Units:true,Currencies:true,     
                 parms:{fltrBranch:true,scurrenciestype:"C",tname:"curr", takei:20},
                 autos:{Branches:true,Units:true,tax_groups:true,},
            },screen:'Items',},
            style:inshst.bascData.all,
      },
    })
    :his.push({
      pathname: '/SimData',
      state: { 
        idata:insh.Units,
        front:
        {elmnts:elmnts.Units,col:col.Units,title:txtA.bascData.unit,
            show:{rightDown:false,}},
        setting:
        {tb:{wait:true, Units:true, parms:{tree:"sub",},autos:{},}, screen:'Units', },
        style:inshst.bascData.all
      },
    })

  }; // E sa



return (
  showSc?<BackdropSc/>:

  <Card className={classes.root}>
  <Grid container className={classes.container} spacing={1}>

  <Grid item lg={12} md={12} sm={12} xs={12}>
  {/* <div style={{color:'green'}} >{riv.Rw+''}</div> */}
  <CardActionArea disabled={!prmiScr.item} 
  onClick={() => clickInv(true)}
   className={classes.action} style={style.backColor.bascData}>

  {/* <div style={{color:'green'}} >{riv.Rw+''}</div> */}
  <CardContent className={classes.content} >
    {/* <Typography component="h5" variant="h5"> */}
    {/* <Typography variant="subtitle1" color="textSecondary">  */}
    <Typography>الاصناف</Typography>
    {/* </Typography>  */}
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <ListAltIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid>

  <Grid item lg={12} md={12} sm={12} xs={12}>
  <CardActionArea disabled={!prmiScr.unit}
  onClick={() => clickInv(false)}
  className={classes.action} style={style.backColor.bascData}>

  <CardContent className={classes.content}>
   {/* <Typography>مجموعة الاصناف</Typography> */}
   <Typography>الوحدات</Typography>
  </CardContent>
  <CardMedia
  className={classes.cover}
  title=""
  >
  <LowPriorityIcon className={classes.icon} />
  </CardMedia>
  </CardActionArea>
  </Grid> 
  </Grid> 

  </Card>

  );
}