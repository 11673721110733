import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import axios from 'axios';
import {uri}  from '../help/Api';
import {sto}  from '../Context/InshData';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import moment from 'moment';

import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import PrintIcon from '@material-ui/icons/Print';
import { Link } from 'react-router-dom';
//Stay
import "../St/Reviews/Stay.css";

const root = { flexGrow: 1, padding: '15px', }

const image =  { width: 128, height: 128, }
const img = { margin: 'auto',display: 'block',maxWidth: '100%', maxHeight: '100%', }
const bakkg = { backgroundColor: 'aliceblue', }
const txtEnd = {textAlign: 'end', }
const table = {  minWidth: 650, }
const itemst={ }
const itmsthedr= {textAlign: 'right'}
const itmsthedc= {textAlign: 'center'}
const totsty = {marginTop: '1%'}
const totstyc = {marginTop: '5px',textAlign: 'center'}
const styfoot= {
  bottom: '1px',
    display: 'flex',
    border: 'solid',
}

const api = axios.create({
  baseURL:uri
});

const stytitE = {
  fontSize: '1.5rem',
}

const stylcontin = {direction:'rtl'}



class ComponentToPrint extends React.Component {
  state = {
    header:[],underheader:[],body:[],
    invType:'',SndoqNam:'', payType:'',saleEmpNamde:'',currLingth:0,shamel:'',
   // footer:[]

  }
  
  constructor(){
super();
//fls
const locDinv = JSON.parse(localStorage.getItem('datainv')) || {};

//fdb header
api.get('Foundation').then( res => {this.setState({header:res.data})});
//fdb
// alert(`MultiMQaForFaPr?id=${locDinv.id}&&PcIdIvPc=${locDinv.ptip}`)
api.get(`MultiMQaForFaPr?id=${locDinv.id}`).then( res => {
  console.log('res.data')
  console.log(res.data)
  this.setState({underheader:res.data,invType:locDinv.invN,SndoqNam:locDinv.SndoqN, saleEmpNamde:sto.usernames})

console.log(locDinv.id+' - '+ res.data.proc_id+' - '+locDinv.ptip);
  api.get(`MultiQSubForFatoraPrint?id=${locDinv.id}`).then( ressub => {

    console.log('ressub.data')
    console.log(ressub.data)
  this.setState({body:ressub.data});

  });



});





}

render() {
  return (
    <div>
        <Paper>

    <Grid container 
    // className={classes.root}
    style={root}
     >

 <Grid item style={itemst} md={5} xs={4}>  
  <Typography variant="h4" gutterBottom>
  <span style={stytitE} className='fontss'> {this.state.header.nameE}</span>
  </Typography>
  <Typography variant="p" gutterBottom>
  <span  className='fontss'>{this.state.header.FieldE}</span>
  </Typography>
  </Grid>

  <Grid item style={itmsthedc} md={2} xs={4}>  
  <ButtonBase 
  style={image}
  // className={classes.image}
  
  >
 <img 
          style={img}
          // className={classes.img}
           alt="complex" src="./logof.png" />
        </ButtonBase>
  </Grid>

  <Grid item style={itmsthedr} md={5} xs={4}>  
  <Typography variant="h5" gutterBottom>
  <span  className='fontss'>
    {/* صادر الاضائة */}
    {this.state.header.nameA}
    </span>
    
  </Typography>
  <Typography variant="p" gutterBottom>
  <span  className='fontss'>
    {/* بيع بالجملة والتجزئة لمواد الانارة الكهرباء */}
    {this.state.header.FieldA}
    </span>
  
  </Typography>
  </Grid>
  
  <Grid item style={itmsthedc} xs={12}>
  <Typography variant="h4" gutterBottom >
      <div className='fontss'>
     <span 
     style={bakkg}>
      {this.state.invType}
      </span>
      </div>
  </Typography>
  </Grid>



  <Grid item style={itemst} xs={6}>
  <Typography variant="h5" gutterBottom >
      <div className='fontss'>
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     >تاريخ</span> {moment(this.state.underheader.date).format('YYYY-MM-DD')}

      </div>
  </Typography>
  </Grid>
  
  <Grid item style={itemst} xs={6}>
  <Typography variant="h5" gutterBottom  >
      <div 
      style={txtEnd}
       >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>رقـــــــــــــــــم</span></span> 
     <span className='fontss'>{this.state.underheader.id}</span>
      </div>
  </Typography>
  </Grid>

  <Grid item style={itemst} xs={12}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>الصندوق/البنك</span> </span>
     <span className='fontss'> {this.state.SndoqNam} </span>
      
      </div>
  </Typography>
  </Grid>

  <Grid item style={itemst} xs={12}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>البيـــــــــــــــان</span></span>
     <span className='fontss'> {this.state.underheader.notes}</span>

      </div>
  </Typography>
  </Grid>



  <Grid item style={itemst} xs={12}>
      <div >
      <TableContainer component={Paper}  style={stylcontin}>
  <Table 
  style={table}
  // className={classes.table}
  aria-label="simple table" >
    <TableHead > 
      <TableRow 
      style={bakkg}
      // className={classes.bakkg}
      >
        <TableCell align="right"> <span className='fontss'>م</span> </TableCell>
        <TableCell align="right"> <span className='fontss'>كود الحساب</span> </TableCell>
        <TableCell align="right"> <span className='fontss'>إسم الحساب</span></TableCell>
        <TableCell align="right"> <span className='fontss'>القيمة</span> </TableCell>
        <TableCell align="right"> <span className='fontss'>رقم المرجع</span></TableCell>
        <TableCell align="right"> <span className='fontss'>ملاحظات</span> </TableCell>
        {/* <TableCell align="right"> <span className='fontss'>Protein(g)</span> </TableCell> */}
      </TableRow>
    </TableHead>
    <TableBody>
      {this.state.body.map((row) => (
        <TableRow key={row.id}>
          <TableCell align="right"> {'*'} </TableCell>
          <TableCell align="right"> {row.acc_no} </TableCell>
          <TableCell align="right">{row.AName}</TableCell>
          <TableCell align="right">{row.credit}</TableCell>
          <TableCell align="right">{row.refno}</TableCell>
          <TableCell align="right">{row.notes}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
      </div>
  </Grid>

 
  <Grid item style={totsty} xs={3}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     ><span className='fontss'>{this.state.underheader.taxval}</span></span> 
      </div>
  </Typography>
  </Grid>
  <Grid item style={totsty} xs={3}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>الضريبة</span> </span> 
      </div>
  </Typography>
  </Grid>
  <Grid item style={totsty} xs={3}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>{this.state.underheader.Val}</span></span> 
      </div>
  </Typography>
  </Grid>
   <Grid item style={totsty} xs={3}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>الاجمالي</span> </span> 
      </div>
  </Typography>
  </Grid>

  <Grid item style={totstyc} xs={12}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={bakkg}
      // className={classes.bakkg}
      >
     {/* <span className='fontss'>اربعون</span>  */}
     <span className='fontss'>----</span> 
      </div>
  </Typography>
  </Grid>


<br/><br/><br/><br/><br/><br/>

<Grid container style={{display:'block', textAlign:'right'}} spacing={0}>

<Grid item style={itmsthedc} xs={6}>
  <Typography variant="h5" gutterBottom >
      <div >  <span className='fontss'>{' / إسم العميل'}</span>  </div>
  </Typography>
  </Grid>

  <Grid item style={itmsthedc} xs={6}>
  <Typography variant="h5" gutterBottom >
      <div >  <span className='fontss'>{' / التوقيع'}</span>  </div>
  </Typography>
  </Grid>

</Grid>

<br/><br/><br/><br/><br/><br/>



<Grid container style={styfoot}  spacing={0}>

  <Grid item style={itemst} xs={12}>
  <hr SIZE="5" WIDTH="100%" ALIGN="center" COLOR="#ddf" NOSHADE />
  </Grid>


  <Grid item style={itmsthedc} xs={12}>
  <Typography variant="h5" gutterBottom >
      <div 
      style={bakkg}
      // className={classes.bakkg}
      > 
     <span className='fontss'>{this.state.header.Address}</span>
      </div>
  </Typography>
  </Grid>
  <Grid item style={itemst} xs={12}>
  <hr SIZE="5" WIDTH="100%" ALIGN="center" COLOR="#ddf" NOSHADE />
  </Grid> 
  <Grid item style={itemst} xs={2}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     ><span className='fontss'>{this.state.header.Fax}</span>  </span> 
      </div>
  </Typography>
  </Grid> 
  <Grid item style={itemst} xs={2}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>الفكس</span> </span> 
      </div>
  </Typography>
  </Grid> 
    <Grid item style={itemst} xs={2}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>{this.state.header.Mobile}</span> </span> 
      </div>
  </Typography>
  </Grid> 
    <Grid item style={itemst} xs={2}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>الجوال</span> </span> 
      </div>
  </Typography>
  </Grid> 
    <Grid item style={itemst} xs={2}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>{this.state.header.Tel}</span> </span> 
      </div>
  </Typography>
  </Grid> 
    <Grid item style={itemst} xs={2}>
  <Typography variant="h5" gutterBottom > 
      <div 
      style={txtEnd}
      // className={classes.txtEnd}
      >
     <span 
     style={bakkg}
    //  className={classes.bakkg}
     > <span className='fontss'>الهاتف</span> </span> 
      </div>
  </Typography>
  </Grid>
  <Grid item style={itemst} xs={12}>
  <hr SIZE="5" WIDTH="100%" ALIGN="center" COLOR="#ddf" NOSHADE />
  </Grid> 

{/* <hr size='100' /> */}
</Grid>


{/* <Grid item style={itemst} xs={12}>
<Button variant="contained" color="primary" href="#contained-buttons" >

<SaveAlt className='fontss'/>
 

  </Button>
  </Grid>  */}

</Grid>


 </Paper>
    </div>
);
};

}

const ReviewMQ = () => {
  const componentRef = useRef();
  const bsty = { width: '36%', margin: '2% 0% 1% 32%', borderRadius: '50px',}

  const bksty = {backgroundColor:'white', }

  return (
    <div style={bksty}>
      <ComponentToPrint ref={componentRef} />
      <ReactToPrint  
                
        trigger={() => <Button startIcon={<PrintIcon />} variant="outlined" color="primary" style={bsty} >طباعة</Button>  }
        
        // () => 
        // print={ ()=>target: <Button variant="outlined" color="primary" style={bsty} >طباعة</Button> => Promise<any>;}
        content={() => componentRef.current} 
     />
      {/* <Button  component={Link} to="/main" variant="outlined" color="primary" style={bsty} >إلغاء</Button>   */}

      <Button  component={Link} to="/" variant="outlined" color="primary" style={bsty} >إلغاء</Button>  
      
    </div>
  );
};


export default ReviewMQ