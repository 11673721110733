import React, { useEffect } from "react";
import { SAdefaultValues } from "./accountingRPT-services";
import {
  Button,
  colors,
  Container,
  Grid,
  LinearProgress,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import AccountingRPTForm from "./accountingRPT-form";
import AccountingRPTTable from "./accountingRPT-table";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import { useHistory } from "react-router-dom";

import ScannerIcon from "@material-ui/icons/Scanner";
import KeyboardReturnSharpIcon from "@material-ui/icons/KeyboardReturnSharp";
import PrintIcon from "@material-ui/icons/Print";
import {
  useStylesSpDial,
  useStylesUiElemment,
} from "../../../../St/Reports/UseStyle";
import BalanceCard from "../../../../components/BalanceCard";
import moment from "moment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
  },
  itemW: {
    // display:'flex',
    width: "90%",
  },
  headerTitle: {
    backgroundColor: "#ffffff",
    textAlign: "center",
    fontSize: 20,
    width: "100%",
  },

  allWidth: {
    minWidth: 650,
  },
  checStyl: {
    marginTop: "17px",
    fontSize: "16px",
    padding: "-1px",
  },
}));

export default function AccountingRPT(props) {
  const { AutoOptions } = props;
  const [rptParams, setRptParams] = React.useState({
    ...SAdefaultValues,
    account: AutoOptions.Accindex[0],
  });
  const { t } = useTranslation(["forms"]);
  const classes = useStyles();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `AccountReports/rptAccBalance?acc_no=${rptParams.account.id}
    &&dateFrom=${rptParams.dateFrom}
    &&dateTo=${rptParams.dateTo}
    &&branch=${rptParams.branch.id}
    &&center=${rptParams.center?.id}
    &&openBalance=${rptParams.openBalance}
    &&oldBalance=${rptParams.oldBalance}
    &&orderType=${rptParams.orderType.id}
    &&docType=${rptParams.docType.id}
    &&omla=${rptParams.omla.id}`,
      dependencyArray: [],
    },
  });

  useEffect(() => {
    console.log("data is", data);
  });
  const his = useHistory();
  const toBack = () => {
    his.push({ pathname: "/" });
  };
  const printHandle = () => {
    const info = {
      data: data?.AccountBalance,
      tot: data?.totals,
      from: "الحساب",
      title: "حركة حساب خلال فترة",
      auto: {},
      account: rptParams.account,
      fDate: rptParams.dateFrom,
      tDate: rptParams.dateTo,
      nDate: moment(new Date()).format("YYYY-MM-DD"),
      ElmntShow: true,
      //totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
    };
    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/ReviewAccount" });
    window.location.reload(false);
  };

  const onChangeData = (propName) => (event) => {
    setRptParams({ ...rptParams, [propName]: event.target.value });
  };
  const onChangeAutoData = (propName, newValue) => {
    setRptParams({ ...rptParams, [propName]: newValue });
  };
  return (
    <div className={classes.root}>
      <Grid container item xs={12}>
        <Typography
          size="small"
          className={classes.headerTitle}
          color="primary"
        >
          {t("acc-move-period")}
        </Typography>
      </Grid>
      <Typography component="div" style={{ direction: "rtl" }}>
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
            borderBottomColor: colors.indigo[300],
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
          }}
        >
          <AccountingRPTForm
            {...{
              AutoOptions,
              rptParams,
              setRptParams,
              onChangeData,
              onChangeAutoData,
              printHandle,
            }}
          />
        </Container>
        <Container maxWidth="xl" style={{ padding: "4px" }}>
          <Grid container lg={12} md={12} sm={12} xs={12} spacing={1}>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<ScannerIcon />}
                color="primary"
                size="large"
                className={[clasui.button]}
                onClick={() => fetchURL()}
              >
                {t("view")}{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={fetching}
                variant="contained"
                startIcon={<PrintIcon />}
                color="primary"
                size="large"
                className={[clasui.button, clasSp.clSec]}
                onClick={printHandle}
              >
                {t("print")}{" "}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                startIcon={<KeyboardReturnSharpIcon />}
                color="secondary"
                size="large"
                className={[clasui.button, clasSp.clcan]}
                onClick={toBack}
              >
                {t("exit")}
              </Button>
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth={true} style={{ padding: "4px" }}>
          <Grid container spacing={1} style={{ paddingTop: "10px" }}>
            <Grid item lg={4} md={4} sm={4} xs={6}>
              <BalanceCard
                {...{
                  title: t("acc-peroid-balance"),
                  dept: data?.totals?.totBlncDept,
                  credit: data?.totals?.totBlncCredit,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={4} xs={6}>
              <BalanceCard
                {...{
                  title: t("acc-peroid-total"),
                  dept: data?.totals?.totDept,
                  credit: data?.totals?.totCredit,
                }}
              />
            </Grid>
          </Grid>
        </Container>
        <br />
        <Container
          component={Paper}
          maxWidth={false}
          style={{
            padding: "10px",
          }}
        >
          <Grid container>
            {fetching ? (
              <Paper>
                {"يرجى الانتظار قليلا حتى يتم جلب البيانات ..."}
                <LinearProgress />
              </Paper>
            ) : (
              <AccountingRPTTable
                {...{ data: data?.AccountBalance ? data.AccountBalance : [] }}
              />
            )}
          </Grid>
        </Container>
      </Typography>
    </div>
  );
}
