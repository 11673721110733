
 import React, { useState,useEffect } from 'react';

 import axios from 'axios';
 import {uri, }  from '../../../help/Api';

 import Grid from '@material-ui/core/Grid';
 import ButtonBase from '@material-ui/core/ButtonBase';
 //import {useStylesHed} from '../../../St/comps/UseStyle';

 function InvBarcode(){
   const locDinv = JSON.parse(localStorage.getItem('datainv')) || {};

   const [found, setFound] = useState({});
   const [inv, setInv] = useState({});


   const getFound = async () => {
     axios.get(uri+'Foundation').then(res => { setFound(res.data); }).catch(error => { });
   }

 const getInv = async () => {
    axios.get(uri+`InvForFatora?proc_id=${locDinv.proc_id}`).then(res => { 
        setInv(res.data); }).catch(error => { });
  }

 useEffect(() => {  
    getFound();
    getInv();
 }, []);

 const sty= {
     dirL:{ direction:"ltr", },

 }
     return (
    <Grid item container style={sty.dirL}>
       {
          inv.tax>0 && 
          <Grid item xs={12} >  
              
            <ButtonBase style={{border:'solid 1px #be151f', }}>
              <img src={`data:image/jpeg;base64,${inv.qr_image}`} style={{height:160,width:160}} alt={found.nameA} /> 

            </ButtonBase>
          </Grid>
        }
    </Grid>
     );
   }
  export default InvBarcode

