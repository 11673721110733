import React, { useEffect } from "react";
import { Chip, colors, makeStyles, Paper } from "@material-ui/core";
import { styled } from "@material-ui/styles";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.2),
}));

export function GroupSelect(props) {
  const { data, groupId, setGroupId } = props;

  useEffect(() => {
    console.log("the group is", groupId);
  });
  const selectStyle = {
    backgroundColor: colors.green[300],
    color: "#fff",
    borderRadius: 10,
    fontSize: 18,
    fontWeight: 800,
  };
  const style = {
    borderRadius: 2,
    fontSize: 16,
    fontWeight: 600,
  };
  return (
    <Paper
      style={{
        direction: "rtl",
        display: "flex",
        justifyContent: "right",
        flexWrap: "wrap",
        listStyle: "none",
        padding: "5px",
        margin: "0px",
      }}
      component="ul"
    >
      <ListItem key={0}>
        <Chip
          label={"كل المجموعات"}
          style={groupId == -1 ? selectStyle : style}
          onClick={() => {
            setGroupId(-1);
          }}
          size="medium"
          clickable
        />
      </ListItem>
      {data?.map((group) => {
        if (group.id == groupId) {
          return (
            <ListItem key={group.id}>
              <Chip
                label={group.name}
                color="success"
                style={selectStyle}
                clickable
              />
            </ListItem>
          );
        }
        return (
          <ListItem key={group.id}>
            <Chip
              label={group.name}
              onClick={() => {
                setGroupId(group.id);
              }}
              style={style}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
}
