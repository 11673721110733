import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import Swipeable from "../Fronts/Main/Swipeable";
import { NotFound } from "../POS/views";

const PrivateRoute = (props) => {
  const { component: Component, isAllow, ...rest } = props;
  if (!isAllow)
    return (
      <Redirect to="/not-found" />
      // <Route
      //   {...rest}
      //   render={(matchProps) => (
      //     <>
      //       <NotFound {...matchProps} />
      //       <Swipeable />
      //     </>
      //   )}
      // />
    );

  return (
    <Route {...rest} render={(matchProps) => <Component {...matchProps} />} />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
  path: PropTypes.string,
  isAllow: PropTypes.string,
};

export default PrivateRoute;
