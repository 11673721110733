import React, { useEffect } from 'react';
// import axios from 'axios';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
import MaterialTable from "material-table";
import {stElemnt} from '../../../../St/Reviews/cStyle';

function BodyA() {
  const cls = useRptAF();
  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  // const [state, setState] = useState({});
  // const getInfo = async () => {
  // axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
// }
var columns = [
  { title: " م", field: "name" },
  { title: "كود الحساب", field: "acc_no" },
  { title: "اسم الحساب", field: "acc_name" },
  { title: "مدين", field: "dept" },
  { title: "دائن", field: "credit" },
  { title: "البيان", field: "notes" }
]
useEffect(() => { /*getInfo(); */ }, []);


return (
<Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
        <MaterialTable style={{boxShadow:'none'}}
          options={{
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: stElemnt.AF.header,
            cellStyle: stElemnt.AF.cell,
            // maxBodyHeight: '28.5rem',
            // minBodyHeight: '28.5rem',
           }}
             
          localization={{ body:{ emptyDataSourceMessage:<h1></h1> ,}, }}
          
              columns={columns}
              data={iInfo.data}
             />
        </Grid>
        <Grid item xs={8} >{'الاجمالي  '}</Grid>
            <Grid item style={{textAlign:'center'}} xs={2} >  {iInfo.total_credit}</Grid>
            <Grid item xs={2} >{iInfo.total_dept}</Grid>
      </Grid>
      <Grid container xs={10} /*className={cls.rootchip}*/>
    
          </Grid>

      <Grid container lg={12} md={12} className={cls.rootBdydown}>
 
      </Grid>

</Grid>
   );

  }

export default BodyA;
