import React from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ReplyIcon from '@material-ui/icons/Reply';

import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

import {useStylesHed, useStylesBA, useStylesTD, useStylesAuto,
     useStylesUp, useStylesTF, useStylesLbl} from '../../../../St/comps/UseStyle';

import {TableIcons as tableIcons} from "../../../../Elemnts/TableIcons";
import MaterialTable from "material-table";
import {Mtb} from '../../../../St/comps/vstyle';
import { Typography } from '@material-ui/core';


export default function SearchInvoiceModal(props){

    const {
        openDw, 
        handCloseDw, 
        transition, 
        showChosedInv,
        invsShow,
        stElemnt,
        style,
        dateFrom,
        dateTo,
        handleDateFrom,
        handleDateTo,
        chang_AutoTypeFltr,
        valueTypeFltr,
        gets,
        searchedInvShow,
        invsShowSearch,
        columnsInv,
        setOr, 
        or,
        timeFrom,
        timeTo,
        handleTimeFrom,
        handleTimeTo, 
    } = props;

    const clasup = useStylesUp();
    const clashd = useStylesHed();
    const clasBA = useStylesBA();
    const clasTF = useStylesTF();
    const clasTD = useStylesTD();
    const clasAo = useStylesAuto();
    const claslbl = useStylesLbl();






   return ( <Dialog fullScreen open={openDw} onClose={handCloseDw}
    TransitionComponent={transition}
    key={transition ? transition.name : ''}
    
    >
    
    <div className={clasBA.continarBASer}>
    <React.Fragment>
    <Grid item container spacing={0} className={clashd.rootHd}>
    
      <Grid item xs={12}>
      <Chip size="small" className={clashd.ChipHdSale} label={'بـحـث فـي الـفـواتـيـر'} color="primary" />
      </Grid>
      
    </Grid>
           
          <CssBaseline />
    <Container maxWidth="xl" className={clasup.AboverootupSer} >
            
      {/* S front H */}    
    <Grid item container spacing={1} className={[clasup.rootup, clasup.rootupSer]}>
    
     
    <Grid item lg={2} md={2} sm={3} xs={4}>
    <TextField fullWidth variant="outlined" size="medium" id="dateinvsf" label="مـن تـاريـخ"
    className={clasTF.root} InputProps={{ className: clasTD.input }}
    type="date" format="YYYY-MM-DD"  InputLabelProps={{shrink: true}}
    name="dateinvsf" value={dateFrom} onChange={handleDateFrom}
    />
    <TextField fullWidth variant="outlined" size="small" id="timeFrom" label="الوقت "
          className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
          type="time" 
          format="HH:mm"
          name="timeFrom" value={timeFrom} onChange={handleTimeFrom}
      />
    </Grid>

    
    <Grid item lg={2} md={2} sm={3} xs={4}>
    <TextField fullWidth variant="outlined" size="medium" id="dateinvsf" label="إلـى تـاريـخ" 
    className={clasTF.root} InputProps={{ className: clasTD.input }}
    type="date" format="YYYY-MM-DD"  InputLabelProps={{shrink: true}}
    name="dateinvsf" value={dateTo} onChange={handleDateTo}
    />
          <TextField fullWidth variant="outlined" size="small" id="timeTo" label="إلى الوقت  "
          className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
          type="time" 
          format="HH:mm"
          name="timeTo" value={timeTo} onChange={handleTimeTo}
      />
    </Grid>
    
    <Grid item lg={1} md={2} sm={2} xs={4}>
    <Autocomplete classes={clasAo}
              value={valueTypeFltr}
              onChange={(event, newValue) => { chang_AutoTypeFltr(newValue) }}
              id="controllable-states-demo"
              options={gets.typesFltr}
              getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
               style={{ marginTop:1 }}
              disableClearable
              renderInput={(params) => <TextField id="typeinvs" {...params} label={'نوع الفاتورة'} variant="outlined" size="medium" 
              style={{ height:20 }} InputLabelProps={{className:claslbl.clor}} />}
            />
    </Grid> 
    
    
    <Grid item lg={3} md={4} sm={4} xs={12}>
    <TextField fullWidth variant="outlined" size="medium" id="searchInvsx`" label="بـحـث" 
    className={clasTF.rootSer} InputProps={{ className: clasTF.input }}  placeholder="بـحـث" 
    type="search"  InputLabelProps={{shrink: true}}
    name="searchInvs" value={searchedInvShow} onChange={invsShowSearch}
    />
    <Typography style={{marginTop:'3px'}}>
      {'الإجمالي: '}{invsShow.reduce((a,v) =>  a = a + v.tot_net , 0 )}
    </Typography>
    </Grid>
    
      </Grid>
      {/* E front H */}
    
      {/* S front B */}
      <Grid item container className={clasup.rootup}>
    
        {/* S MatTableOld */}
     
      <Grid item  md={12} sm={12} xs={12} >
                <MaterialTable style={{boxShadow:'none'}}
                //style={{direction:'ltr'}}
              options={{ 
                showTitle:false,
                toolbar: false,
                sorting: false,
                selection: false,
                // ToolBar Search
                searchFieldAlignment:"left",
                searchFieldVariant:"outlined",
                // ToolBar header
                showSelectAllCheckbox:false,
                showTextRowsSelected:false, 
                pageSize:5,
                paginationType:"stepped",
                showFirstLastPageButtons:false,
                
                paging:false,
    
                maxBodyHeight: Mtb.minmaxBHInvs,
                minBodyHeight: Mtb.minmaxBHInvs,
                // padding: 'dense',
                rowStyle: stElemnt.InvsR,
                headerStyle: stElemnt.InvsH,
               }}
              localization={{
                body:{ emptyDataSourceMessage:<h3 style={{color:'#000',}} >لا توجد فواتير بعد</h3> ,},
                toolbar: { searchPlaceholder: "بـحـث", },
              }}
              
              columns={columnsInv}
              data={invsShow}
              icons={tableIcons}
              
              onRowClick={(event, rowData) => {
               setOr({...or, postput:'toPut',PrAC:0 })
               console.log('row data is', rowData);
               showChosedInv(false,rowData.proc_id);
               event.stopPropagation();
             }}
    
                 />
                </Grid> 
    
         {/* E MatTableNew */}
    
      </Grid>
      {/* E front B */}
    
      <br/> <br/>
    
          </Container>
        </React.Fragment>
     </div>
    
    
     {/* ====================================== end ======================================== */}
         <React.Fragment>
         <CssBaseline />
         <AppBar position="fixed" color="primary"
          // className={clasBA.appBarBASal}
           style={{...style.bgImg,...stElemnt.all.appBarBA}}
         >
           <Toolbar variant="dense">
             
            <Hidden smUp={['sm']}>
            <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handCloseDw}>
              <CloseIcon />
            </IconButton>
          </Hidden>
    
          <Hidden only={['xs']}>
            <Button style={{color:'#fff'}} onClick={handCloseDw} startIcon={<ReplyIcon />}>الفاتورة</Button>
          </Hidden>
    
             <div className={clasBA.growBA} />
    
           </Toolbar>
         </AppBar>
       </React.Fragment>
    
    </Dialog>
    )
}