import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React  from "react";
import { cellS, headerS } from "../../../../St/comps/cStyle";

import {useStylesContainers,} from '../../../../St/comps/UseStyle';
function getItemFromOptions(id, options){
  if(id)
    return options.filter(g=>g.id==id)[0];
  return '';
}
const UserTable =(props)=>{

    const { users, onShow} = props;
  const clsConts = useStylesContainers();
    const columns = [
    {title: "الرقم", field: "id", },
    {title: "الموظف", field: "emp", },
    {title: "المستخدم", field: "name", },
    {title: "كلمة المرور", field: "password", },
    {title: "الفرع ", field: "branch", },
    {title: "المخزن", field: "safe", },
    {title: "الصندوق", field: "stock", },
  ];

    function onRowClickHandle(data){

        onShow({...data, 
          safe: getItemFromOptions(data.safe, data.SafeEmp),
          stock: getItemFromOptions(data.stock, data.StockEmp),
          nPassword: data.password,
          nName: data.name,
          }, "edit"); 

    }
    return(
        
 <Grid item container lg={12} md={12} className={[clsConts.rootdown,]}>
    <Grid item container xs={12} className={[clsConts.subDown,clsConts.subDownC,]}>
      <Grid item xs={12}>
           <MaterialTable style={{boxShadow:'none'}}

                title=''
              // variant="outlined"
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,
              maxBodyHeight: '65vh',
              minBodyHeight: '65vh',
             }}

             localization={{

              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
              columns={columns}
             data={users}
             
             onRowClick={(event, rowData) => {
                onRowClickHandle(rowData);
              // event.stopPropagation();
              event.preventDefault();
            }}/>
      </Grid>    
    </Grid>
    </Grid>
    )
}

export default UserTable;