import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
// import {}  from '../help/Api';
import { insh } from "../Context/InshData";
// import {locConn}  from '../Context/Locstor';
import { Apis } from "../help/Apis";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
// import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import ReplayIcon from "@material-ui/icons/Replay";
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import LinkIcon from "@material-ui/icons/Link";
import LinearProgress from "@material-ui/core/LinearProgress";
import PhonelinkSetupIcon from "@material-ui/icons/PhonelinkSetup";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from "@material-ui/core/Slide";

import { tEnsh, msgA, txtA } from "../Label/Lbl";
import { useStylesTF, useStylesBT } from "../St/Fronts/UseStyle";

import DialogNotes from "../utils/DialogNotes";
import { getLS } from "../utils/useLS";

import * as https from "https";
process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

const useStylesLin = makeStyles({
  rootlin: { width: "100%" },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url()",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    // backgroundColor: theme.palette.secondary.main,
    backgroundImage: "linear-gradient(to right, #4e9e84 , #2688ba)",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(7, 0, 2),
    // background: 'crimson',
    // color: 'cornsilk',
    backgroundImage: "linear-gradient(to right, #4e9e84 , #2688ba)",
    boxShadow: "none",
    height: "4rem",
    borderRadius: 5,
    fontSize: 20,
  },
  btn: {
    background: "red",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  Box: {
    bottom: "14px",
    position: "absolute",
  },
  phoneseting: {
    color: "#02958a",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Endemam() {
  const classes = useStyles();
  const classesLin = useStylesLin();
  const clasTF = useStylesTF();
  const clasBT = useStylesBT();
  let his = useHistory();

  const locConn = JSON.parse(window.localStorage.getItem("infoConn")) || {};

  const [cont, setCont] = useState({});
  const [lbl, setLbl] = useState(tEnsh.endemam);

  const [cloud, setCloud] = useState(insh.cloud);

  const inshShow = {
    typeConnct: locConn.typeConnct,
    btnAllow: false,
    progress: false,
    val: 0,
    disable: false,
  };
  const [show, setShow] = useState(inshShow);
  // const [val, setVal] = useState(0);

  const [state, setState] = useState(insh.con);

  const [dNoteState, setDNoteState] = useState(false);
  const [dNote, setDNote] = useState({ title: "", sub: "", txtBtn: "" });
  const clickDialogNots = (title, sub, txtBtn) => {
    setDNoteState(!dNoteState);
    setDNote({ title: title, sub: sub, txtBtn: txtBtn });
  };

  let uri =
    state.ftp + "://" + state.ip + state.endDigtip + state.port + "/api/";

  // const refreshPage = () => window.location.reload(false);

  const setLocItems = () => {
    console.log(state);
    // alert(JSON.stringify(state))
    window.localStorage.setItem("infoConn", JSON.stringify(state));
    window.localStorage.setItem(
      "showCurr",
      JSON.stringify({ statu: false, rout: "D" })
    );
    // refreshPage();
    his.replace({ pathname: "/" });
  };

  const inshState = {
    // server:'',
    //ip:'',
    // namedb:'OraxLite',
    // id:'sa',
    // pass:'orax055266',
    // port:':443',
    // ftp:'https',
    // typeConnct:false,
    useUri: false,
    leavle: "accsept",
  };

  const goAccsept = () => {
    //  localStorage.removeItem("infoConn");
    window.localStorage.setItem("infoConn", JSON.stringify(inshState));
    window.localStorage.removeItem("showCurr");
    window.localStorage.removeItem("showCurr");
    his.replace({ pathname: "/" });

    //  refreshPage();
  };

  const changInfo = (prop) => (e) => {
    // alert(e.target.value);

    // if(state.ftp=='http'){
    // alert('state.typeConnc_t = '+state.typeConnct)
    if (state.typeConnct === true) {
      if (e.target.value === cloud.demoapp.phone) {
        // alert(e.target.value +' == '+ cloud.demoapp.phone +' __ '+cloud.demoapp.serv)
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.demoapp.serv,
        });
      } else if (e.target.value === cloud.safe.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.safe.serv,
        });
      } else if (e.target.value === cloud.silverbox.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.silverbox.serv,
        });
      } else if (e.target.value === cloud.nawi.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.nawi.serv,
        });
      } else if (e.target.value === cloud.magles.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.magles.serv,
        });
      } else if (e.target.value === cloud.alhelwa.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.alhelwa.serv,
        });
      } else if (e.target.value === cloud.almostaqbl.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.almostaqbl.serv,
        });
      } else if (e.target.value === cloud.alsodasia.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.alsodasia.serv,
        });
      } else if (e.target.value === cloud.amazon.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.amazon.serv,
        });
      } else if (e.target.value === cloud.azharseven.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.azharseven.serv,
        });
      } else if (e.target.value === cloud.hozan.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.hozan.serv,
        });
      } else if (e.target.value === cloud.tobala.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.tobala.serv,
        });
      } else if (e.target.value === cloud.albena.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.albena.serv,
        });
      } else if (e.target.value === cloud.trana.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.trana.serv,
        });
      } else if (e.target.value === cloud.anwar.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.anwar.serv,
        });
      } else if (e.target.value === cloud.alwanAltwreed.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.alwanAltwreed.serv,
        });
      } else if (e.target.value === cloud.qahwasalesa.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.qahwasalesa.serv,
        });
      } else if (e.target.value === cloud.gawansharq.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.gawansharq.serv,
        });
      } else if (e.target.value === cloud.oniestplas.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.oniestplas.serv,
        });
      } else if (e.target.value === cloud.mamonaahmed.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.mamonaahmed.serv,
        });
      } else if (e.target.value === cloud.dekawikaya.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.dekawikaya.serv,
        });
      } else if (e.target.value === cloud.nasmah.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.nasmah.serv,
        });
      } else if (e.target.value === cloud.aljuda.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.aljuda.serv,
        });
      } else if (e.target.value === cloud.roaimelan.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.roaimelan.serv,
        });
      } else if (e.target.value === cloud.mageda.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.mageda.serv,
        });
      } else if (e.target.value === cloud.demo2.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.demo2.serv,
        });
      } else if (e.target.value === cloud.aqwab_zahab.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.aqwab_zahab.serv,
        });
      } else if (e.target.value === cloud.mohand_said.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.mohand_said.serv,
        });
      } else if (e.target.value === cloud.ronq_tara.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.ronq_tara.serv,
        });
      } else if (e.target.value === cloud.almomares.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.almomares.serv,
        });
      } else if (e.target.value === cloud.asfar.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.asfar.serv,
        });
      } else if (e.target.value === cloud.shbakada.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.shbakada.serv,
        });
      } else if (e.target.value === cloud.shrakatdwa.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.shrakatdwa.serv,
        });
      } else if (e.target.value === cloud.holmsafar.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.holmsafar.serv,
        });
      } else if (e.target.value === cloud.bareq_shemal.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.bareq_shemal.serv,
        });
      } else if (e.target.value === cloud.sharkatalnama.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.sharkatalnama.serv,
        });
      } else if (e.target.value === cloud.sharkatawsal.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.sharkatawsal.serv,
        });
      } else if (e.target.value === cloud.sharkatemar.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.sharkatemar.serv,
        });
      } else if (e.target.value === cloud.alihammed.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.alihammed.serv,
        });
      } else if (e.target.value === cloud.rehamabdullah.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.rehamabdullah.serv,
        });
      } else if (e.target.value === cloud.mazaqtesaa.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.mazaqtesaa.serv,
        });
      }
      else if (e.target.value === cloud.alzoalnaqe.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.alzoalnaqe.serv,
        });
      }
      else if (e.target.value === cloud.alihasnalali.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.alihasnalali.serv,
        });
      }
      else if (e.target.value === cloud.warshatfahad.phone) {
        setState({
          ...state,
          [prop]: e.target.value,
          endDigtip: cloud.warshatfahad.serv,
        });
      }
       else setState({ ...state, [prop]: e.target.value, endDigtip: "" });
    } else {
      // alert('in else')
      e.target.value.length < 4
        ? setState({
            ...state,
            [prop]: e.target.value,
            endDigtip: e.target.value,
          })
        : clickDialogNots("", cont.cont[4], "فـهـمـت");
    }
  };

  // const timerRef = React.useRef();

  // const tstConnect = async () =>
  // {
  //   for (let m = 1; m <= 255; m++) {

  //    let tip= state.ftp+'://'+state .ip+m+state.port+'/api/';
  //     // console.log('tip')
  //     // console.log(tip)
  //     await axios.get(`${tip}Types`)
  //     .then(res => { state.leavle='signIn'; setLocItems(); alert(tip) })
  //     .catch(error => {console.log(tip)})

  //   }
  // }

  const tstConnect = async () => {
    alert(`${uri}Types` + " in tstConnect");
    const instance = axios.create({
      httpsAgent: new https.Agent({
        rejectUnauthorized: false,
      }),
    });
    //  instance.get(`${uri}Types`);

    const agent = new https.Agent({
      rejectUnauthorized: false,
    });

    // https.globalAgent.options.rejectUnauthorized = false;

    // await fetch(`${uri}Types`)
    await axios
      .get(`${uri}Types`)
      .then((res) => {
        // alert('thin')
        state.leavle = "signIn";
        state.useUri = true;
        setLocItems();
      })
      .catch((error) => {
        // setVal(0);
        console.log("the uri isssssssssssss", uri);
        console.log(error);

        // alert('قم بكتابة الـ ip بشكل صحيح');
        clickDialogNots("", cont.cont[state.mode], "فـهـمـت");
        // 'قم بكتابة الـ ip بشكل صحيح'
        // 'قـم بكتابـة الـ ' + 'ip' + 'بشكل صحيح'
        setShow({
          ...show,
          btnAllow: valueFtp.state,
          progress: false,
          val: 0,
          disable: false,
        });
      });
  };

  const givip = async (e) => {
    e.preventDefault();

    if (state.typeConnct) {
      if (state.endDigtip == "" && state.changip == "")
        clickDialogNots(
          "رسالة توضيحية",
          cont.cont[2] + lbl.TF[state.mode],
          "فـهـمـت"
        );
      else if (state.endDigtip == "")
        clickDialogNots("رسالة توضيحية", cont.cont[3], "فـهـمـت");
      else {
        setShow({
          ...show,
          btnAllow: false,
          progress: true,
          val: 10,
          disable: true,
        });

        tstConnect();
      }
    } else {
      if (state.endDigtip == "") {
        clickDialogNots(
          "رسالة توضيحية",
          cont.cont[2] + lbl.TF[state.mode],
          "فـهـمـت"
        );
      } else {
        // alert('else Z');
        // setVal(10);
        setShow({
          ...show,
          btnAllow: false,
          progress: true,
          val: 10,
          disable: true,
        });

        tstConnect();

        //  setDBName();
      }
    }
  };

  const optionsAutos = [
    {
      ftp: [
        { id: 1, name: "https", lable: "شبكة", state: true },
        { id: 2, name: "http", lable: "كلاود على الإنترنت", state: false },
      ],
      // port : [{id:1,name:'80'},{id:2,name:'443'}]
    },
  ];

  const [valueFtp, setValueFtp] = useState({});
  // const [valuePort, setValuePort] = useState(optionsAutos[0].port[0]);

  // const fhs = () => { console.log('Autos'); console.log(valueFtp); console.log(state); console.log(show); }

  const changAutos = (newVal) => {
    // prop==='ftp' ? setValueFtp(newVal) : setValuePort(newVal);
    // let chcont=''
    // if(newVal.name=='http') {
    //   chcont= cont.conthttp;
    // }
    // else {
    //   chcont= cont.conthttps;
    // }
    // setCont({...cont,
    //   body:{ cont:chcont }
    // })
    // setState({...state,endDigti_p:'',changip:''})

    setValueFtp(newVal);
    changConn(newVal.name);
    setTtype(newVal.name != "http" ? "number" : "text");
    clkCloseD();
  };

  const changConn = (val) => {
    if (val === "http") {
      setShow({ ...show, btnAllow: false, typeConnct: true });
      setState({
        ...state,
        ftp: "https",
        ip: "",
        id: "",
        pass: "",
        port: "",
        namedb: "",
        typeConnct: true,
        endDigtip: "",
        changip: "",
        mode: 1,
      });
      setCont({ ...cont, body: { cont: cont.cont[1] } });
    } else if (val === "https") {
      setShow({ ...show, typeConnct: false });
      //  setState({ ...state,ftp:'http',ip:state.ipinsh, id:'sa',pass:'orax055266',port:':80',namedb:'OraxLite',typeConnct:false });
      //setState({ ...state,ftp:'http',ip:state.ipinsh, id:'sa',pass:'orax055266',port:':8184',namedb:'OraxLite',typeConnct:false,endDigtip:'',changip:'',mode:0 });
      setState({
        ...state,
        ftp: "https",
        ip: state.ipinsh,
        id: "sa",
        pass: "orax055266",
        port: ":443",
        namedb: "OraxLite",
        typeConnct: false,
        endDigtip: "",
        changip: "",
        mode: 0,
      });
      setCont({ ...cont, body: { cont: cont.cont[0] } });
    }
  };

  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(show.val);
  const progressRef = React.useRef(() => {});
  const [ttype, setTtype] = useState("");

  useEffect(() => {
    setLbl(txtA.endemam);
    // locConn
    let loc = getLS("infoConn", {});
    // console.log('locConn')

    setState(loc);
    // alert(locConn.ftp)
    setCont(msgA.endemam);

    // alert('locConn.ftp')

    if (loc.mode == 0) {
      setValueFtp(optionsAutos[0].ftp[0]);
      setTtype("number");
    } else {
      setValueFtp(optionsAutos[0].ftp[1]);
      setTtype("text");
    }
  }, []);

  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(show.val);
      } else {
        const diff = Math.random() * show.val;
        const diff2 = Math.random() * show.val;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
        // console.log(buffer +' - '+progress)
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const [openD, setOpenD] = React.useState(false);
  const clkOpenD = () => {
    setOpenD(true);
  };
  const clkCloseD = () => {
    setOpenD(false);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Grid container component="main" className={classes.root}>
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar} onClick={goAccsept}>
              <ReplayIcon />
            </Avatar>
            {/* <Typography component="h1" variant="h6">
          ip ادخـل الـ
          </Typography> */}
            <br />

            <Grid item md={7} sm={8} xs={10} elevation={6} square>
              <Grid item xs={4}>
                {/* <div>{JSON.stringify(state)}</div> */}
              </Grid>
              <form onSubmit={givip} className={classes.form}>
                {/* <TextField fullWidth variant="outlined" margin="normal"
              label="اسم السيرفر" placeholder="" autoComplete="false" autoFocus
              id="server" name="server"
              value={state.server} onChange={changInfo('server')}
            /> */}
                {/* <TextField type="text" autoFocus fullWidth variant="outlined" size="small"
              label="النظام جهاز ip" placeholder="مثال 192.168.1.1" autoComplete="false" 
              id="ip" name="ip"
              value={state.ip} onChange={changInfo('ip')}
              style={{marginTop:'4rem',direction:'ltr'}}
              className={clasTF.root} InputProps={{ className: clasTF.input }}
            /> */}
                {/* <div>{JSON.stringify(lbl.TF[0])}</div> */}
                <TextField
                  type={ttype}
                  autoFocus
                  fullWidth
                  variant="outlined"
                  size="small"
                  label={lbl.TF[state.mode]}
                  placeholder=""
                  autoComplete="false"
                  id="changip"
                  name="changip"
                  value={state.changip}
                  onChange={changInfo("changip")}
                  style={{ marginTop: "4rem", direction: "ltr" }}
                  className={clasTF.root}
                  InputProps={{ className: clasTF.input }}
                />
                {/* <Grid item lg={7} md={12} sm={7} xs={6}>
 <TextField type="text" fullWidth variant="outlined" size="small" id="symbol" label="إسم الصنف"
 className={clasTF.root} InputProps={{ className: clasTF.input }}
 name="symbol" value={subInfo.symbol} />
</Grid> */}
                <Button
                  disabled={show.disable}
                  type="submit"
                  fullWidth
                  color="secondary"
                  startIcon={<LinkIcon />}
                  //  variant="contained"
                  variant={show.disable ? "outlined" : "contained"}
                  className={classes.submit}
                >
                  {" "}
                  ربـط{" "}
                </Button>
                <br /> <br />
                {show.progress === true ? (
                  <div className={classesLin.rootlin}>
                    <LinearProgress
                      variant="buffer"
                      value={progress}
                      valueBuffer={buffer}
                    />
                  </div>
                ) : null}
              </form>
            </Grid>

            <br />

            {show.btnAllow && (
              <Grid container ex={10} justify="center">
                <Button
                  href={`${state.ftp}://${state.ip}${state.endDigtip}${state.port}/`}
                  target="_blank"
                  rel="noopener"
                  color="inherit"
                  size="medium"
                  startIcon={<LinearScaleIcon />}
                  className={clasBT.link}
                >
                  {"انقر هنا للإنتقال لـ السماح"}
                  {/* {' او اسم السيرفر ip تأكد من ال'} */}
                  {/* {' ip تأكد من الـ '} */}
                </Button>
              </Grid>
            )}
          </div>

          <Grid container justifyContent="center">
            <IconButton onClick={clkOpenD} disabled={show.disable}>
              <PhonelinkSetupIcon
                fontSize="large"
                className={classes.phoneseting}
              />
            </IconButton>
          </Grid>

          {/* <Button onClick={fhs} color="inherit" fullWidth size="medium"> فحص </Button> */}
        </Grid>

        <Dialog
          maxWidth={"xs"}
          fullWidth
          open={openD}
          TransitionComponent={Transition}
          onClose={clkCloseD}
        >
          <DialogTitle id="alert-dialog-slide-title">
            {"إعـدادات السيرفر"}
          </DialogTitle>

          <DialogContent>
            {/* <div>{typeof(state.mode)+''}</div> */}
            <Autocomplete
              fullWidth //classes={clasAo}
              value={valueFtp}
              onChange={(event, newValue) => {
                changAutos(newValue);
              }}
              id="controllable-states-ftp"
              size="small"
              options={optionsAutos[0].ftp}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.lable
              }
              style={{}}
              disableClearable
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="نوع الإتصال"
                  variant="outlined"
                  size="small"
                />
              )}
            />
            <br />
            {show.typeConnct == true ? (
              <Fragment>
                {/* <TextField fullWidth variant="outlined" id="namedb" margin="dense"
          label="قاعدة البيانات" name="namedb" value={state.namedb} onChange={changInfo('namedb')} />
          <TextField fullWidth variant="outlined" id="id" margin="dense"
          label="المستخدم" name="id" value={state.id} onChange={changInfo('id')} autoComplete="false" />
          <TextField fullWidth variant="outlined" id="pass" margin="dense"
          type="password" autoComplete="current-password" 
          label="كلمة المرور" name="pass" value={state.pass} onChange={changInfo('pass')} autoComplete="false" /> 
         */}
              </Fragment>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button onClick={clkCloseD} color="primary">
              مـوافـق
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>

      <DialogNotes
        DialogOpen={dNoteState}
        DialogClose={() => setDNoteState(false)}
        content={{ title: dNote.title, sub: dNote.sub, txtBtn: dNote.txtBtn }}
      />
    </React.Fragment>
  );
}
