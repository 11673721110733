import React, { useEffect } from 'react';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
import MaterialTable from "material-table";
import {stElemnt} from '../../../../St/Reviews/cStyle';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { colors } from '@material-ui/core';

function BodyA() {
  const cls = useRptAF();

const loc = useLocation();
const [data, setData] = useState(loc?.state?.data);
useEffect(() => { console.log(loc.state) }, []);

  var columns = [
    { title: " رقم الحساب", field: "Code" },
    { title: "اسم العميل", field: "aname" },
    { title: "مدين", field: "OBX_Dept" },
    { title: "دائن", field: "OBX_Credit" },
    { title: "حركة مدين", field: "dept" },
    { title: "حركةدائن", field: "credit" },
    { title: "الرصيد", field: "balance" },
    { title: "الحالة", field: "state" }
  ]

return (
<Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
        <MaterialTable style={{boxShadow:'none'}}
            //style={{direction:'ltr'}}
          options={{
            showTitle:false,
            toolbar: false,
            sorting: false,
            selection: false,
            // ToolBar Search
            searchFieldAlignment:"left",
            searchFieldVariant:"outlined",
            // ToolBar header
            showSelectAllCheckbox:false,
            showTextRowsSelected:false, 
            // body
            // rowStyle:(data,i)=>i%2!=0?{background:'#038b0321'}:null,
            // footer
            pageSize:5,
            paginationType:"stepped",
            showFirstLastPageButtons:false,
            
            paging:false,
            headerStyle: {...stElemnt.AF.header, backgroundColor: colors.grey[50]},
            cellStyle: stElemnt.AF.cell,
            // maxBodyHeight: '28.5rem',
            // minBodyHeight: '28.5rem',
           }}
           
          localization={{ body:{ emptyDataSourceMessage:<h5 style={{color:'GrayText'}}>
            لا توجد ارصدة بعد في هذا الحساب
          </h5> ,}, }}
          
              columns={columns}
              data={data}
             />
        </Grid>
      </Grid>

      <Grid container lg={12} md={12} className={cls.rootBdydown}>
          <Grid container xs={12} className={cls.rootinfAcc}>
            <Grid item xs={2} className={[cls.infAcc]}>{'المدير العام'}</Grid>
            <Grid item xs={2} className={[cls.infAcc]}>{'المدير المالي'}</Grid>
            <Grid item xs={2} className={[cls.infAcc]}>{'المراجع'}</Grid>
            <Grid item xs={2} className={[cls.infAcc]}>{'المحاسب'}</Grid>
            <Grid item xs={4} className={[cls.infAcc]}>{'المستخدم'}</Grid>
          </Grid>
          <Grid container xs={12} className={[cls.rootinfAcc,cls.downinfAcc]}>
            <Grid item xs={2} className={[cls.infAcc]}>{'--------------------'}</Grid>
            <Grid item xs={2} className={[cls.infAcc]}>{'--------------------'}</Grid>
            <Grid item xs={2} className={[cls.infAcc]}>{'--------------------'}</Grid>
            <Grid item xs={2} className={[cls.infAcc]}>{'--------------------'}</Grid>
            <Grid item xs={4} className={[cls.infAcc]}>{''}</Grid>
          </Grid>
      </Grid>

      {/* totTasdeed:totTasdeed.toFixed(2),
      totDaen:totDaen.toFixed(2),
      // totMdeen:totMdeen.toFixed(2) */}


</Grid>
   );

  }

export default BodyA;
