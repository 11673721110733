import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { insh as inshst } from '../../../Context/InshSt';

const useStyles = makeStyles((theme) => ({
      table: {
        minWidth: 650,
      },
}));

export default function BalanceCustRPTTable(props){


    const {data} = props;

    const classes = useStyles();
    return(
      <TableContainer component={Paper} >
       <Table className={classes.table} size="small" aria-label="a dense table">
         <TableHead>
           <TableRow style={{backgroundColor: inshst.colors.cust, color:'#fff'}}>
             <TableCell style={{color:'#fff'}} align="center">رقم الحساب </TableCell>
             <TableCell style={{color:'#fff'}} align="center">اسم العميل</TableCell>
             <TableCell style={{color:'#fff'}} align="center">مدين</TableCell>
             <TableCell style={{color:'#fff'}} align="center">دائن </TableCell>
             <TableCell style={{color:'#fff'}} align="center">حركة مدين</TableCell>
             <TableCell style={{color:'#fff'}} align="center">حركة دائن</TableCell>
             <TableCell style={{color:'#fff'}} align="center">الرصيد </TableCell>
             <TableCell style={{color:'#fff'}} align="center">الحالة</TableCell>
             <TableCell  align="center"></TableCell>
           </TableRow>
         </TableHead>
         <TableBody>
           {data?.map((row) => (
             <TableRow key={row.id}>
               <TableCell align="center">{row.Code}</TableCell>
               <TableCell align="center">{row.aname}</TableCell>
               <TableCell align="center">{row?.OBX_Dept?.toFixed(2)}</TableCell>
               <TableCell align="center">{row?.OBX_Credit?.toFixed(2)}</TableCell>
               <TableCell align="center">{row?.dept?.toFixed(2)}</TableCell>
               <TableCell align="center">{row?.credit?.toFixed(2)}</TableCell>

               <TableCell align="center">{(row?.end_Dept+row?.end_Credit).toFixed(2)}</TableCell>
               <TableCell align="center">{row?.end_Dept > 0 ? "مدين":"دائن"}</TableCell>
               <TableCell align="center">{}</TableCell>
             </TableRow>
           ))}
         </TableBody>
       </Table>
     </TableContainer>
    )
}