import moment from "moment/moment";

export const selectTypes = [
  { id: 1, finalAcc: 1, name: "mezania", label: "balance-sheet" },
  { id: 2, finalAcc: 2, name: "arbah", label: "profit-loss" },
  { id: 3, finalAcc: -1, name: "", label: "trial-balance" },
];

export const levelOptions = [
  { id: -1, label: "الكل" },
  { id: 1, label: "1" },
  { id: 2, label: "2" },
  { id: 3, label: "3" },
  { id: 4, label: "4" },
];

export const aMdefaultValues = {
  dateFrom: moment(new Date()).subtract(6, "month").format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: { id: -1, name: "الكل" },
  center: { id: -1, name: "الكل" },
  level: levelOptions[0],
  type: selectTypes[0],
  chk_Prev: true,
};
