import React, { useEffect } from "react";
// import axios from 'axios';
// import {uri}  from '../../../../help/Api';
import { Rept } from "../../../../Context/InshData";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import { useRptAF } from "../../../../St/Reviews/UseStyle";
// import { Paper } from '@material-ui/core';
import MaterialTable from "material-table";
import { stElemnt } from "../../../../St/Reviews/cStyle";
import { mt } from "../../../../St/comps/cStyle";

function BodyA() {
  const cls = useRptAF();

  var columns = [
    { title: "الرصيد", field: "balance" },
    { title: "مدين", field: "dept" },
    { title: "دائن", field: "credit" },
    { title: "رقم المستند", field: "doc_no" },
    { title: "تاريخ المستند", field: "date" },
    { title: "رقم المرجع", field: "demoo" },
    { title: "البيان", field: "notes", cellStyle: mt.note },
  ];

  useEffect(() => {
    /*getInfo(); */
  }, []);

  return (
    <Grid item container className={cls.rootBdy}>
      <Grid item container className={cls.rootBdyup}>
        <Grid item xs={12}>
          <MaterialTable
            style={{ boxShadow: "none" }}
            // style={{direction:'ltr'}}
            options={{
              showTitle: false,
              toolbar: false,
              sorting: false,
              selection: false,
              // ToolBar Search
              searchFieldAlignment: "left",
              searchFieldVariant: "outlined",
              // ToolBar header
              showSelectAllCheckbox: false,
              showTextRowsSelected: false,
              pageSize: 5,
              paginationType: "stepped",
              showFirstLastPageButtons: false,

              paging: false,
              headerStyle: stElemnt.AF.header,
              cellStyle: stElemnt.AF.cell,
              // maxBodyHeight: '28.5rem',
              // minBodyHeight: '28.5rem',
            }}
            localization={{
              body: {
                emptyDataSourceMessage: (
                  <h5 style={{ color: "GrayText" }}>
                    لا توجد ارصدة بعد في هذا الحساب
                  </h5>
                ),
              },
            }}
            columns={columns}
            data={Rept.data}
          />
        </Grid>
      </Grid>

      <Grid container lg={12} md={12} className={cls.rootBdydown}>
        <Grid container xs={12} className={cls.rootinfAcc}>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المدير العام"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المدير المالي"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المراجع"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"المحاسب"}
          </Grid>
          <Grid item xs={4} className={[cls.infAcc]}>
            {"المستخدم"}
          </Grid>
        </Grid>
        <Grid container xs={12} className={[cls.rootinfAcc, cls.downinfAcc]}>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={2} className={[cls.infAcc]}>
            {"--------------------"}
          </Grid>
          <Grid item xs={4} className={[cls.infAcc]}>
            {""}
          </Grid>
        </Grid>
      </Grid>

      <Grid container lg={12} md={12} className={cls.rootBdydown}>
        <Grid container xs={10} /*className={cls.rootchip}*/>
          <Grid item xs={5} className={[cls.chipT, cls.chipTR]}>
            {"إجمالي الفترة المحددة"}
          </Grid>
          <Grid item xs={2} className={[cls.chipV, cls.chipVR]}>
            {Rept.tot.totDept}
          </Grid>
          <Grid item xs={2} className={[cls.chipV, cls.chipVR]}>
            {Rept.tot.totCredit}
          </Grid>
        </Grid>
        <Grid container xs={10} /*className={cls.rootchip}*/>
          <Grid item xs={5} className={[cls.chipT, cls.chipTR]}>
            {"رصيد الفترة المحددة"}
          </Grid>
          <Grid item xs={2} className={[cls.chipV, cls.chipVR]}>
            {Rept.tot.totBlncDept}
          </Grid>
          <Grid item xs={2} className={[cls.chipV, cls.chipVR]}>
            {Rept.tot.totBlncCredit}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BodyA;
