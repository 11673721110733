import moment from "moment/moment";

export const selectTypes = [
  { id: 1, name: "Stocks", label: "stock" },
  { id: 2, name: "Customers", label: "customer" },
  { id: 3, name: "Currencies", label: "item" },
  { id: 4, name: "Month", label: "months" },
  { id: 5, name: "Day", label: "days" },
  { id: 6, name: "salesmen", label: "salesman" },
  { id: 7, name: "Users", label: "user" },
  { id: 8, name: "Group", label: "group" },
  // {id:1, name:'Stocks', label:'الصندوق'},
  // {id:2, name:'Customers', label:'العميل'},
  // {id:3, name:'Currencies', label:'الصنف'},
  // {id:4, name:'Month', label:'الشهور'},
  // {id:5, name:'Day', label:'الأيام'},
  // {id:6, name:'salesmen', label:'مندوب البيع'},
  // {id:7, name:'Users', label:'المستخدم'},
  // {id:8, name:'Group', label:'مجموعة الصنف'},
];

export const SAdefaultValues = {
  selectType: selectTypes[0],
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  safe: { id: -1, name: "الكل" },
  cust: { id: -1, name: "الكل" },
  user: { id: -1, name: "الكل" },
  stock: { id: -1, name: "الكل" },
  sales_man: { id: -1, name: "الكل" },
  group: { id: -1, name: "الكل" },
  branch: { id: -1, name: "الكل" },
  chk_withvat: false,
  type_cost: 1,
};
