import React, { useState, useEffect } from "react";

import axios from "axios";
import { uri } from "../../../help/Api";

import Grid from "@material-ui/core/Grid";
import { sto } from "../../../Context/InshData";
//import {useStylesHed} from '../../../St/comps/UseStyle';

function Footer(props) {
  const locDinv = JSON.parse(localStorage.getItem("datainv")) || {};
  const { onUnmountComplete } = props;
  const [found, setFound] = useState({});
  const [inv, setInv] = useState({});

  const getFound = async () => {
    axios
      .get(uri + "Foundation")
      .then((res) => {
        setFound(res.data);
      })
      .catch((error) => {});
  };

  const getInv = async () => {
    axios
      .get(uri + `InvForFatora?proc_id=${locDinv.proc_id}`)
      .then((res) => {
        setInv(res.data);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setTimeout(async () => {
      await getFound();
      await getInv();
      console.log("child footer");
      onUnmountComplete();
    }, 0);
  }, []);

  const sty = {
    cntr: { textAlign: "center", padding: 10, fontFamily: "Amiri-Regular" },
    lft: { textAlign: "left", padingLeft: 5 },
    rit: { textAlign: "right", padingRight: 5 },
    dirR: { direction: "rtl", color: "black" },
    dirL: { direction: "ltr", padding: 10 },
  };
  return (
    <Grid item container style={sty.cntr}>
      <Grid item container>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={10}
          style={{ border: "solid 0.1px", padding: 10, direction: "rtl" }}
        >
          {found.BillNotes}
        </Grid>
        <Grid item xs={1}></Grid>
        <Grid item xs={12}>
          {"_____"}
        </Grid>
        <Grid item xs={12}>
          {sto.usernames}
        </Grid>
      </Grid>
    </Grid>
  );
}
export default Footer;
