import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { uri } from "../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvMain } from "../../../St/Reviews/UseStyle";
import { colors, Container, Divider, makeStyles } from "@material-ui/core";
import { getBillSettings } from "../../../services/settings-services";

const useDone = makeStyles((theme) => ({
  /* S Head */
  headerContainer: {
    width: "20.4cm",
    marginLeft: "0.3cm",
  },
  minclr: {
    color: "#7f0101",
    fontWeight: "bold",
    fontFamily: "Amiri-Regular",
    boxShadow: "0px 0px 0px 0px rgb(0 0 0 / 0%)",
  },
  minroot: {
    // flexGrow: 1,
    // display:'flex',
    marginTop: 10,
    border: "solid 1px",
    borderRadius: "10px",
    padding: "5px",

    // zIndex: 1,
  },
  rightHeader: {
    textAlign: "center",
    whiteSpace: "normal",
    fontFamily: "Amiri-Regular",
    fontWeight: "900",
  },
}));

function Header() {
  const clasm = useInvMain();
  const clas = useInvAF();
  const classHeader = useDone();
  // const clasH = useInvHeadrAF();
  const [settingData, setSettingData] = useState({});
  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = { GetFoundation: true, parms: { iFoundid: 1 }, autos: {} };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
      })
      .catch((error) => {});
  };
  //  const secondColor = '#31b835';
  const secondColor = "#C07F00";
  const styf = { fontWeight: "1200", fontSize: 20, color: secondColor };
  const stysub = { fontWeight: "bold", color: secondColor };
  const styfC = { fontWeight: "500" };
  useEffect(() => {
    const lt = async () => {
      const setting = JSON.parse(localStorage.getItem("billSTGs"));
      setSettingData(setting);
    };
    lt();
    getInfo();
  }, []);

  if (settingData?.dsplyHeadFootr)
    return <div style={{ height: settingData?.sasInvHeadr }}></div>;
  return (
    <Fragment>
      <Grid
        item
        container
        className={classHeader.headerContainer}
        style={{ padding: 5, fontWeight: "bold" }}
      >
        <Grid item xs={5} style={{ textAlign: "center" }}>
          {/* <Typography variant="h6" gutterBottom>
              {info.nameE}
            </Typography> */}
          <div style={styf}>{info.nameE}</div>
          <div style={stysub}>{info.FieldE}</div>
          <div style={styfC}>{"record number / " + info.bsn_no}</div>{" "}
          <div style={styfC}>{info.Disc4_header_en}</div>
        </Grid>

        <Grid item xs={2} className={clasm.mincntr}>
          <div>
            <ButtonBase>
              {" "}
              <img
                src={`data:image/jpeg;base64,${info.Logo}`}
                className={clas.Hedlogo}
                alt={info.nameA}
              />{" "}
            </ButtonBase>
          </div>
        </Grid>

        <Grid item xs={5} className={classHeader.rightHeader}>
          {/* <Typography style={styf} variant="h5" gutterBottom>
            {info.nameA}
          </Typography> */}
          <div style={styf}>{info.nameA}</div>{" "}
          <div style={stysub}>{info.FieldA}</div>
          <div style={styfC}>{"رقم السجل / " + info.bsn_no}</div>{" "}
          <div style={styfC}>{info.Disc4_header_ar}</div>
        </Grid>
      </Grid>
      <Container>
        <Divider style={{ background: secondColor, height: 5 }} />
      </Container>
    </Fragment>
  );
}
export default Header;
