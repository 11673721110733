import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import React  from "react";
import {headerS, cellS} from './groups-style';
import {useStylesContainers,} from '../../../../St/comps/UseStyle';

const GroupsTable =(props)=>{

    const {groups, onShow} = props;
  const clsConts = useStylesContainers();
   var columns = [
    {title: 'id', field:"id", hidden: true,},
    { title: "كود المجموعة", field: "group_id" },
    { title: "مجموعة الصنف ", field: "name" },
    ];

    function onRowClickHandle(data){
        if(data.parent_id == 0){
            onShow({...data, type:'main',
            parent:{id:0}}, "edit"); 
        }
        else{
            const parent = groups.filter(g=>g.id==data.parent_id);
            onShow({...data, 
                type:'sub',
            parent: parent[0]}, "edit");  
        }
    }
    return(
        
 <Grid item container lg={12} md={12} className={[clsConts.rootdown,]}>
    <Grid item container xs={12} className={[clsConts.subDown,clsConts.subDownC,]}>
      <Grid item xs={12}>
           <MaterialTable style={{boxShadow:'none'}}

                title=''
              // variant="outlined"
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,
              maxBodyHeight: '65vh',
              minBodyHeight: '65vh',
             }}

             localization={{

              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
              columns={columns}
             data={groups}
             
             onRowClick={(event, rowData) => {
                onRowClickHandle(rowData);
              event.stopPropagation();
            }}/>
      </Grid>    
    </Grid>
    </Grid>
    )
}

export default GroupsTable;