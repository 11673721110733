
 import React, { useState,useEffect } from 'react';

 import axios from 'axios';
 import {uri, }  from '../../../../help/Api';

 import Grid from '@material-ui/core/Grid';
 import Typography from '@material-ui/core/Typography';
 import ButtonBase from '@material-ui/core/ButtonBase';
 //import {useStylesHed} from '../../../../St/comps/UseStyle';
 import {useInvCa,useInvMain} from '../../../../St/Reviews/UseStyle';

 function Footer(){
  const clas = useInvCa();
  const clasm = useInvMain();
   //const clashd = useStylesHed();
  //  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
  //  const locRem = JSON.parse(localStorage.getItem('DataRem')) || {};
   const locDinv = JSON.parse(localStorage.getItem('datainv')) || {};

   const [found, setFound] = useState({});
   const [inv, setInv] = useState({});

  //  const inshInfoInv = {
  //   tot:'الاجمالي', totBefurDisc:'الإجمالي قبل الخصم',
  //   totDisc:'اجمالي الخصم', totAfterDisc:'الإجمالي بعد الخصم',
  //   totBefurAmount:'المجموع قبل الضريبة',totAmount:'إجمالي الضريبة',
  //   tax:'ضريبة القيمة المضافة',shaml:'الإجمالي يشمل القيمة المضافة',
  //   cash:'المدفوع',agel:'المتبقي',
  //   note:'البيان', tell:'رقم الهاتف',phone:'الجوال',
  //  }

  //  const [infoInv, setinfoInv] = useState(inshInfoInv);

   const getFound = async () => {
     axios.get(uri+'Foundation').then(res => { setFound(res.data); }).catch(error => { });
   }

 const getInv = async () => {
    axios.get(uri+`InvForFatora?proc_id=${locDinv.proc_id}`).then(res => { 
        setInv(res.data); }).catch(error => { });
  }

 useEffect(() => {  
    getFound();
    getInv();
 }, []);

 const sty= {
     cntr:{textAlign:'center', },
     lft:{textAlign:'left',padingLeft:5, },
     rit:{textAlign:'right',padingRight:5, },
     dirR:{ direction:"rtl",color:'black' },
     dirL:{ direction:"ltr", },
 }
 
     return (
      // <span style={{color:'black'}}>
    <Grid item container style={sty.dirR}>
       <Grid item container style={sty.cntr} spacing={0}>
         <br/>
        <Grid xs={12} >
           <Typography className={[clas.contntSub]}
           style={{margin:'0.8rem 0.1rem 0.2rem 0.1rem',borderRadius:5, color:'#5e5e5e',background:'#ffffee',padding:'0.2rem 0.2rem 0.2rem 0.2rem',}}
           >{found.Address}</Typography>
        </Grid>        
  {/* <Grid item xs={12}>{'....'}</Grid> */}
  <br/>  <br/>
       </Grid>

      <Grid item container className={clas.Bdycntr}>
        <Grid item xs={4} className={clasm.minclr}>{'هاتف'}</Grid>
        <Grid item xs={4} className={clasm.minclr}>{'جوال'}</Grid>
        <Grid item xs={4} className={clasm.minclr}>{'فاكس'}</Grid>

        <Grid item xs={4} style={{fontSize:'0.8rem', fontWeight:'bold'}} >{found.Tel}</Grid>
        <Grid item xs={4} style={{fontSize:'0.8rem', fontWeight:'bold'}} >{found.Mobile}</Grid>
        <Grid item xs={4} style={{fontSize:'0.8rem', fontWeight:'bold'}} >{found.Fax}</Grid>
      </Grid>

    </Grid>
    // </span>

     );
   }
  export default Footer

