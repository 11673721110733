import React, { useState,useEffect } from 'react';
import axios from 'axios';
import {uri, }  from '../../../../help/Api';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import {useRptCa} from '../../../../St/Reviews/UseStyle';

 function FooterC() {
  const clas = useRptCa();
  const [state, setState] = useState({});
  const getInfo = async () => {
  axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
}

useEffect(() => { 
  getInfo();
}, []);

    return (
      <Grid item container className={clas.rootFotr}>

<Grid item container className={clas.BdytotSub}>
      <Grid item xs={6} className={[clas.Bdytot,clas.BtotT]}>{'رصيد الصندوق للفتره'}</Grid> 
      <Grid item xs={5} className={[clas.Bdytot,clas.BtotV]}>{Rept.tot.totRsedStok}</Grid> 
      <Grid item xs={6} className={[clas.Bdytot,clas.BtotT]}>{'رصيد الشبكة للفتره'}</Grid>
      <Grid item xs={5} className={[clas.Bdytot,clas.BtotV]}>{Rept.tot.totRsedNetW}</Grid>
    </Grid>

      </Grid>        
    );
  }
export default FooterC;


