import React, { useState, useEffect } from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import SafeGrdUnitsForm from "./SafeGrdUnits-form";
import BackdropScI from "../../../../services/BackdropScI";

const defaultValues = {
  dateFrom: moment(new Date()).format("YYYY-MM-DD"),
  dateTo: moment(new Date()).format("YYYY-MM-DD"),
  branch: { id: -1, name: "الكل" },
  safe: { id: -1, name: "الكل" },
  costCenter: { id: -1, name: "الكل" },
  cust: { id: -1, name: "الكل" },
  group: { id: -1, name: "الكل" },
  itemsTypeVat: "0",
  chkZero: false,
  chkMinus: false,
  makhzni: true,
  kham: false,
  masnai: false,
  user_id: -1,
};

const defaultData = {
  SafeGrdUnits: [],
  totals: {
    rowsCount: 0,
    TotalSumCost: 0,
    TotalSumSalePrice: 0,
  },
};
export default function SafeGrdUnits() {
  let his = useHistory();
  const locat = useLocation();

  const [rptParams, setRptParams] = React.useState(defaultValues);

  const { data, fetching, fetchURL } = useFetchURL({
    ...{
      url:
        uri +
        `SafeGrdUnits?safe=${rptParams.safe.id}
    &&chkZero=${rptParams.chkZero}
    &&chkMinus=${rptParams.chkMinus}
    &&group_id=${rptParams.group.id}
    &&makhzni=${rptParams.makhzni}
    &&kham=${rptParams.kham}
    &&masnai=${rptParams.masnai}`,
      dependencyArray: [],
    },
  });

  useEffect(() => {
    console.log("histort is ", data);
  });

  const onChangeData = (event) => {
    setRptParams({ ...rptParams, [event.target.name]: event.target.checked });
  };

  const printHandle = () => {
    const info = {
      ...data,
      dateFrom: rptParams.dateFrom,
      dateTo: rptParams.dateTo,
      title: "locat.state.titleHed",
    };

    his.push({ pathname: "/TSP", state: info });

    window.location.reload(false);
  };

  return (
    <SafeGrdUnitsForm
      {...{
        data: data?.SafeGrdUnits ? data : defaultData,
        onChangeData,
        printHandle,
        rptParams,
        setRptParams,
        fetchURL,
        fetching,
        title: "locat.state.titleHed",
        systemId: 0,
      }}
    />
  );
}
