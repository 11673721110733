import moment from "moment/moment";
import { locRem, locSto } from "../Context/Locstor";
import * as yup from 'yup'
import { insh } from "../Context/InshData";
import { getAccountOmlat } from "./omlat-services";

export  const ERVdefaultValues={
      id:'',
      date:moment(new Date()).format('YYYY-MM-DD'),
      // cust_id:locSto.CustomersA != undefined ? locSto.CustomersA[0].id: -1,
      cust_id:'',
      customer:null,
      val:'',
      val1:'',
      tot_val1:0,
      tot_val:0,
      type:'1',
      safe_bank_id:locSto.Stocks != undefined ? locSto.Stocks[0].id: -1,
      sales_emp:locSto.Employees != undefined ? locSto.Employees[0].id: -1,
      emp:null,
      bank:null,
      stock: null,
      check_no:'',
      check_date:moment(new Date()).format('YYYY-MM-DD'),
      checkbank:'',
      check_state:null,
      notes:'',
      rest_id:'',
      branch:locRem.branchi,
      center:{id:'-1', name:''},
      ref_no:'',
      end_Dept: '',
     };

export const EReceiptValidateSchema = yup.object().shape({
  val: yup.string().required("يجب ادخال المبلغ"),
  cust_id: yup.string().required("يجب اختيار العميل"),
  val1: yup.string().required("يجب ادخال مبلغ الاستلام المقابل"),
  tot_val: yup.number().required(''),
  tot_val1: yup.number().oneOf([yup.ref('tot_val'), null], "يجب ان يكون المبلغ المحلي متساوي"),

})
export const EExchangeValidateSchema = yup.object().shape({
  val: yup.string().required("يجب ادخال المبلغ"),
  cust_id: yup.string().required("يجب اختيار العميل"),
})

export function PrepareData(data){

      let nData ={
            ...data, 
            cust_id: data.customer? data.customer.id: '',
            sales_emp: data.emp? data.emp.id: '',
            center: data.center.id,
            safe_bank_id: data.type === '1'? data.stock.id : data.bank.id,
            check_state: data?.check_state? data.check_state.id: '',
      }
      
      return nData;
}

export function PrepareDataWithOmla(data){

      let nData ={
            ...data, 
            omla: data?.cust_omla?.id,
            omlaval: data?.cust_omla.exchange,
            omla1: data.type == '1'? data?.stock_omla?.id : data?.bank_omla?.id,
            omlaval1: data.type == '1' ? data.stock_omla?.exchange : data?.bank_omla?.exchange,
            cust_id: data.customer? data.customer.id: '',
            sales_emp: data.emp? data.emp.id: '',
            center: data.center.id,
            safe_bank_id: data.type === '1'? data.stock.id : data.bank.id,
            check_state: data?.check_state? data.check_state.id: '',
      }
      
      return nData;
}

function GetItemFromOptions(id, options){
      const index = options.findIndex((op)=>op.id ==id);
      return options[index];
}
export function IRReFormatingData(data, AutoOptions){
      let emp = '', bank='', stock='', check_state='';
      emp= GetItemFromOptions(data.sales_emp, AutoOptions.Users);

      if(data.type =='1'){
            stock = GetItemFromOptions(data.safe_bank_id, AutoOptions.Stocks);
            bank = AutoOptions.Banks[0];      
            // cust_omlat = {}
      }
      else{
            bank = GetItemFromOptions(data.safe_bank_id, AutoOptions.Banks);
            stock = AutoOptions.Stocks[0];
            check_state= data.check_state? GetItemFromOptions(data.check_state, insh.autos.CheckStatus):'';

      }

      let nData ={
            ...data, 
            customer: {id:data.cust_id, name: data.custName},
            center: data.center === -1? ERVdefaultValues.center : 
            GetItemFromOptions(data.center, AutoOptions.costcenters),
            stock: stock,
            emp: emp,
            bank: bank,
            check_state: check_state,
            user: data.emp_id? GetItemFromOptions(data.emp_id, AutoOptions.Users) : ''
      }
      

      return nData;

}

export const getEndDept=(end_dept)=>{
      const text = end_dept < 0 ? '    دائن': '     مدين'
      return Math.abs(end_dept).toFixed()+ text;
}