import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { uri, } from '../../../../help/Api';
import { Rept } from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { useRptAF } from '../../../../St/Reviews/UseStyle';
import { makeStyles } from '@material-ui/core/styles';
// import { stElemnt } from '../../../St/Reviews/cStyle';
import { stElemnt } from '../../../../St/Reviews/cStyle';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({

  itemW: {
    // display:'flex',
    textAlign: 'right',
    fontSize: '0.8rem',
    //  fontWeight:'700',
    fontWeight: 'bold',
  },
  item2: {
    // display:'flex',
    textAlign: 'center',
    fontSize: '0.8rem',
    //  fontWeight:'700',
    fontWeight: 'bold',
  },
}));

function HeaderA() {
  const classes = useStyles();

  const cls = useRptAF();
  const iInfo = JSON.parse(localStorage.getItem('iInfos')) || {};
  const [state, setState] = useState({});
   const loc = useLocation();
  const Rpt = loc.state;
  const getInfo = async () => {
    axios.get(uri + 'Foundation').then(res => { setState(res.data); }).catch(error => { });

  }

  useEffect(() => {
    getInfo();
    console.log("location data is " , Rpt)
  }, []);

  return (

    <Grid item container className={cls.root}>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography>{state.nameE}</Typography>
        <Typography>{state.FieldE}</Typography>
      </Grid>
      <Grid item xs={2} >
        <ButtonBase>
          <img src={`data:image/jpeg;base64,${state.Logo}`} className={cls.logo} alt={state.nameA} />
        </ButtonBase>
      </Grid>
      <Grid item xs={5} className={cls.rootsub}>
        <Typography>{state.nameA}</Typography>
        <Typography>{state.FieldA}</Typography>
      </Grid>

      <Grid item xs={12} className={[cls.title, cls.titleM]}>
        <Typography > <b>تقرير تحليلي مبيعات</b> </Typography>
      </Grid>

      <Grid item container className={[cls.title, cls.titleS]}>

        <Grid item container className={cls.titLin2}>
          <Grid item className={classes.itemW} xs={1}>{'الخزينة' + ' ' + '/'}</Grid>
          <Grid item className={classes.itemW} xs={2}>{Rpt.stock.name}</Grid>
          <Grid item xs={1}></Grid>
          <Grid item className={classes.itemW} xs={1}>{'المخزن' + '    ' + '/'}</Grid>
          <Grid item xs={2} className={classes.itemW}>{Rpt.safe.name}</Grid>
          <Grid item xs={1}></Grid>
          <Grid item className={classes.itemW} xs={1}>{'العميل' + '    ' + '/'}</Grid>
          <Grid item xs={2} className={classes.itemW}>{Rpt.cust.name}</Grid>
          <Grid item xs={2}>  </Grid>
        </Grid>

        <Grid item container className={cls.titLin2}>

          <Grid item className={classes.itemW} xs={2} >{'مندوب المبيعات' + '   ' + '/'}</Grid>
          <Grid item className={classes.itemW} xs={2} >{Rpt.sales_man.name}</Grid>

          <Grid item className={classes.itemW} xs={1} >{'المستخدم' + '  ' + '/'}</Grid>
          <Grid item className={classes.itemW} xs={2} >{Rpt.user.name}</Grid>

          <Grid item className={classes.itemW} xs={2} >{'مجموعة الصنف' + '  ' + '/'}</Grid>
          <Grid item className={classes.itemW} xs={2} >{Rpt.group.name}</Grid>

        </Grid>


        <Grid item container className={cls.titLin2}>


          <Grid item className={classes.item2} xs={3} >{' من تاريخ' +'  ' +'/'}</Grid>
          <Grid item className={classes.itemW} xs={3} >{Rpt.dateFrom}</Grid>
          <Grid item className={classes.item2} xs={3} >{' الى تاريخ' +'  ' +'/'}</Grid>
          <Grid item className={classes.itemW} xs={3} >{Rpt.dateTo}</Grid>
        </Grid>

      </Grid>

    </Grid>
  );

  // return (


  // <Grid item container justifyContent='center' spacing={0}>



  //   <Grid item container className="">

  //   </Grid>

  //   <Grid item container className="">
  //  </Grid>

  //   <Grid item container className="">
  //  </Grid>
  // </Grid>

  // </Grid>
  // );

}
export default HeaderA;


