import React, { useState,useRef,Component, Fragment } from 'react';
import ReactToPrint from 'react-to-print';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import PrintIcon from '@material-ui/icons/Print';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import  HeaderA from './A4/HeaderA';
import  BodyA  from './A4/BodyA';
import  FooterA from './A4/FooterA';
import {useStylesSpDial} from '../../../St/Reviews/UseStyle';
import {stElemnt} from '../../../St/Reviews/cStyle';
import jsPDF from 'jspdf';
import { callAddFont } from '../../amiri-font';
import ShareIcon from '@material-ui/icons/Share';
import ArrowDownward from '@material-ui/icons/ArrowDownward';


class ComponentToPrint extends Component {

  constructor() { super(); this.state = { } }
  render() {

return (
      <Fragment>
        <Typography component="div" style={stElemnt.AF.Reviws.Typogmain}>
        <Typography component="div" style={stElemnt.AF.Reviws.Typogsub}>
            <HeaderA/>
            <BodyA/>
            <FooterA/>
          </Typography>
        </Typography>
      </Fragment>
);

  }
}

const RevAccounting = () => {
  const componentRef = useRef();
  const reportDownloadRef = useRef();
  const clasSp = useStylesSpDial();

  const [open, setOpen] = useState(true);
  const handleOpen = () => { setOpen(true); };
  const handleClose = () => { setOpen(true); };
  let his = useHistory();

  const back = (path) =>
  {path ? his.push({ pathname: '/'}) : his.goBack();}
  
  const shareData = async () => {
    jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc = new jsPDF("p", "pt", "a3");
    doc.setFont("Amiri-Regular", "normal");
    
    let mfile;
    await doc.html(reportDownloadRef.current, {
      async callback(doc) {
        var blob = await doc.output("blob");
        mfile = new File([blob], "ReviewAccount.pdf", { type: blob.type });
      }
    });

    if (navigator.share && navigator.canShare({ files: [mfile] })) {
      await navigator.share({
        title: "hello to orax app",
        text: "welcome this is text",
        url: "orax.app/",
        files: [mfile]
      });
    } else {
      alert("cant share files");
    }
  };

  const handleGeneratePdf = async() => {
    
      jsPDF.API.events.push(["addFonts", callAddFont]);
    var doc =  new jsPDF("p", "pt", "a3");
    doc.setFont("Amiri-Regular", "normal");
    doc.html(reportDownloadRef.current, {
      async callback(doc) {
        await doc.save("ReviewAccount");
      },
    });
  };
  
  const actions = [

    { icon: <ReactToPrint trigger={() => <PrintIcon /> } content={() => componentRef.current} />, name: 'طباعـة' },
    { icon: <ArrowDownward className={clasSp.ico} onClick={handleGeneratePdf} />, name: 'تنزيل' },
    { icon: <ShareIcon className={clasSp.ico} onClick={shareData} />, name: 'مشاركة' },
    { icon: <ArrowBackIcon className={clasSp.ico} onClick={()=> back(false)}  />, name: 'رجـوع' },
  ];

  return (
    <div className={clasSp.root}>
      <Container maxWidth='xl' style={stElemnt.AF.Reviws.continr}> 
        <CssBaseline />
        <div ref={reportDownloadRef} lang={'ar'} dir={'rtl'} style={{fontFamily: 'Amiri-Regular', 
          fontStyle:"normal", letterSpacing:'normal'}}>
        <ComponentToPrint ref={componentRef} />
        </div>
      </Container>
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDial} hidden={false}
        icon={<SpeedDialIcon openIcon={<HomeIcon />} onClick={()=> back(true)} />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action,i) => ( <SpeedDialAction key={i}
         icon={action.icon} tooltipTitle={action.name} onClick={handleClose} />))}
      </SpeedDial>
    </div>
  );
};


export default RevAccounting;
