import { GroupSelect } from "../../../../components/invoice/group-select";
import { getLS } from "../../../../utils/useLS";
import { createStateContext } from "react-use";
import React, { useEffect } from "react";
import { useState } from "react";
import ProductsGrid from "../../../../components/invoice/item-grid";
import SearchBox from "../../../../components/invoice/search-box";
import { Paper } from "@material-ui/core";

export const [useSharedGroupId, SharedGroupIdProvider] = createStateContext(-1);

export function Products(props) {
  const { dataTable } = props;
  const locSto = getLS("sto", {});

  const [groupId, setGroupId] = useState(-1);

  useEffect(() => {
    console.log("the data is", dataTable);
  }, [groupId, dataTable]);
  return (
    <React.Fragment>
      <Paper style={{ maxHeight: 500, overflow: "auto" }}>
        <SharedGroupIdProvider>
          {/* <SearchBox /> */}

          <GroupSelect
            data={[...locSto.groups, ...locSto.groupsSub]}
            //   subs={locSto.groupsSub}
            groupId={groupId}
            setGroupId={setGroupId}
          />
          <ProductsGrid
            onSelectItem={props.onSelectItem}
            values={dataTable}
            groupId={groupId}
            BefrowDataCurrencie={null}
          />
        </SharedGroupIdProvider>
      </Paper>

      {/* <Swipeable /> */}
    </React.Fragment>
  );
}
