import React, { useState, useEffect } from "react";
import { txtA, txtE } from "../../../Label/Lbl";
import axios from "axios";
import { uri } from "../../../help/Api";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ButtonBase from "@material-ui/core/ButtonBase";
import { useInvAF, useInvCa, useInvMain } from "../../../St/Reviews/UseStyle";

function Header() {
  const clasm = useInvMain();
  const clas = useInvCa();
  const [info, setInfo] = useState({});
  const getInfo = async () => {
    const tb = { GetFoundation: true, parms: { iFoundid: 1 }, autos: {} };
    axios
      .post(`${uri}Res`, tb)
      .then((res) => {
        setInfo(res.data.Found);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <Grid item container className={clas.Hedrest} spacing={0.5}>
      <Grid item xs={12} className={clasm.mincntr}>
        <Typography>
          <ButtonBase>
            {" "}
            <img
              src={`data:image/jpeg;base64,${info.Logo}`}
              className={clas.Hedlogo}
              alt={info.nameA}
            />{" "}
          </ButtonBase>
        </Typography>
      </Grid>

      <Grid item xs={12} className={[clasm.mincntr]}>
        <Typography className={[clas.contnt]}>{info.nameE}</Typography>
      </Grid>
      <Grid item xs={12} className={[clasm.mincntr]}>
        <Typography className={clas.contnt}>
          {"Phone No:"}
          {info.Mobile}
        </Typography>
      </Grid>
      {/*  */}

      <Grid item xs={12} className={clasm.mincntr}>
        <Typography className={clas.contnt}>{"VAT# " + info.tax_no}</Typography>
      </Grid>
    </Grid>
  );
}
export default Header;
