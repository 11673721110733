import React from "react";
import { Switch, Redirect, useRouteMatch, Route } from "react-router-dom";
import AccountingRPTCon from "../comps/Accounts/BackEnd/AccountingRPT/accountingRPTCon";
import EExchangeVoucherCon from "../comps/Accounts/BackEnd/receipt-voucher/external-exchange-voucher/EEVCon";
import EReceiptVoucherCon from "../comps/Accounts/BackEnd/receipt-voucher/external-receipt-voucher/ERVCon";
import ItemsCon from "../comps/bascDatas/Tab1/items/ItemsCon";
import InvoiceCon from "../comps/Invs/BackEnd/invoiceCon";
import Body from "../comps/Sales/BackEnd/Tab1Rout/EveryDay/Body";
import RevAccounting from "../Reviews/Accounts/Balance/RevAccounting";
import RevSandC from "../Reviews/Accounts/Kharji/Casher/RevSandC";
import VoucherTemplate from "../Reviews/Accounts/voucher-template/voucher-template";
import BillTemplate from "../Reviews/Invs/bill-template/bill-template";
import ResturantCasher from "../Reviews/Invs/casher-resturant/RevCasher";
import RevCasher from "../Reviews/Invs/CasherReadyDisigen/RevCasher";
import CashairNoTax from "../Reviews/Invs/CashierNoTax/CashairNoTax";
import GoldTemplate from "../Reviews/Invs/gold-template/gold-template";
import ReviewEveryDay from "../Reviews/Sales/EvryDay/ReviewEveryDay";
import Bigen from "../Start/Bigen";
import RevAF from "../Reviews/Invs/AFReadyDisigen/RevAF";
import { RouteWithLayout } from "./components";
import { Main as MainLayout } from "./layouts";

import { NotFound as NotFoundView } from "./views";
import { SubPrintCasher } from "../comps/Settings/PrintCasher";
import { sto } from "../Context/InshData";

const Routes = () => {
  if (!sto)
    return (
      <Switch>
        <Route exact path="/" component={Bigen} />
      </Switch>
    );

  return (
    <Switch>
      <Route exact path="/" component={Bigen} />
      <Route
        component={InvoiceCon}
        exact
        // layout={MainLayout}
        path="/invoices"
        isAllow={true}
      />
      <RouteWithLayout
        component={ItemsCon}
        exact
        layout={MainLayout}
        path="/products"
        isAllow={true}
      />
      <RouteWithLayout
        exact
        layout={MainLayout}
        path="/ReporteveryDay"
        component={Body}
        isAllow={sto.used_Dialy_screen}
      />
      <Route path="/ReviewEDay" component={ReviewEveryDay} />

      <RouteWithLayout
        component={AccountingRPTCon}
        exact
        layout={MainLayout}
        path="/rpted"
        isAllow={sto.used_Accnt_screen}
      />
      <Route path="/ReviewAccount" component={RevAccounting} />

      <RouteWithLayout
        component={EReceiptVoucherCon}
        exact
        layout={MainLayout}
        path="/ext-receipt"
        isAllow={sto.used_Sqabd_screen}
      />
      <RouteWithLayout
        component={EExchangeVoucherCon}
        exact
        layout={MainLayout}
        path="/ext-exchange"
        isAllow={sto.used_SSarf_screen}
      />
      <Route path="/voucher2" component={VoucherTemplate} />
      <Route path="/SC" component={RevSandC} />

      <RouteWithLayout
        component={SubPrintCasher}
        exact
        layout={MainLayout}
        path="/settings"
        isAllow={true}
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
        isAllow={true}
      />
      <Route path="/A" component={RevAF} />
      <Route path="/bill2" component={BillTemplate} />
      <Route path="/gold-bill" component={GoldTemplate} />
      <Route path="/C" component={RevCasher} />
      <Route path="/CashairNoTax" component={CashairNoTax} />
      <Route path="/RestCasher" component={ResturantCasher} />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
