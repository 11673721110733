import React, {useState} from 'react'
import {Typography} from '@material-ui/core'
import Barcode from 'react-barcode/lib/react-barcode';

function BarcodeGenerator(props) {
    const {barcode, itemName, price} = props;

    return (
      <div style={{textAlign:'center', backgroundColor: '#fff', padding:'10px'}}>
            <Typography style={{padding:'0', marginBottom:'-3px',}}>{itemName}</Typography>
                    <Barcode 
                        id="mybarcode" value={barcode} background='#ffffff'
                        lineColor='black'
                        width="2"
                        height="50"
                        format="CODE128"
                        displayValue='true'
                        font='monospace'
                        textAlign='center'
                        textPosition='bottom'
                        textMargin='3'
                        fontSize='12'
                        marginTop='0'
                        marginBottom='0'
                    />
            <Typography style={{direction:'rtl', marginTop:'-10px',  backgroundColor:'transparent',
             padding:'0'}} color={'primary'}>  {price}  {'رس  '}</Typography>
      </div>
    );
  }
  
  export default BarcodeGenerator;
  