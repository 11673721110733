import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { uri } from "../../../../help/Api";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Typography from "@material-ui/core/Typography";
import { Autocomplete } from "@material-ui/lab";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { sto } from "../../../../Context/InshData";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import PrintIcon from "@material-ui/icons/Print";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveeIcon from "@material-ui/icons/Save";
import RestrictionAccountForm from "./restriction-account-form";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { useHistory } from "react-router-dom";
import PostAddIcon from "@material-ui/icons/PostAdd";
import toast, { Toaster } from "react-hot-toast";
import { restrictionValidateSchema } from "./restriction-validate-schema";
import RestricSubTable from "./restriction-sub-table";
import SearchIcon from "@material-ui/icons/Search";
import RestrictionSearchModal from "./restriction-search-modal";
import { Delete } from "@material-ui/icons";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { restrictDefaultValue } from "../../../../services/restrictionService";
import { useStyles } from "./restrictionInsert-style";
import moment from "moment";

export default function RestrictionInsert(props) {
  const { data: accountBooks, fetching: accountBkFetching } = useFetchURL({
    ...{
      url: uri + "AccountBooks",
      dependencyArray: [],
    },
  });

  let branches = [{ id: sto.branchi, Name: sto.branchNames }];

  const clasess = useStyles();
  const [accBookValue, setAccBookValue] = React.useState(0);
  const [branchValue, setBranchValue] = useState({
    id: sto.branchi,
    Name: sto.branchNames,
  });
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [state, setState] = React.useState({
    checkedA: true,
  });

  const sty = {
    marginLeft: "20px",
    marginRight: "10px",
    marginBottom: "6px",
  };

  let his = useHistory();
  const backHome = () => {
    his.push({ pathname: "/" });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const [restrictData, setRestrictData] = useState(restrictDefaultValue);

  const [alldata, setAlldata] = useState([]);
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const addSubRestrictionFunc = (line) => {
    setAlldata([...alldata, line]);
  };

  const loadRestrictionSubs = async (_idR) => {
    await axios
      .get(uri + `RestrictionSubs?_idR=${_idR}`)
      .then((res) => {
        const newData = res.data.map((row) => {
          return {
            ...row,
            acc_name: row.AName,
            centerView: row.costName === null ? "بدون" : row.costName,
          };
        });
        setAlldata(newData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changetf = (propName) => (e) => {
    setRestrictData({ ...restrictData, [propName]: e.target.value });
  };

  const submiteRestriction = (type) => async (e) => {
    e.preventDefault();
    if (alldata.length === 0) {
      toast.error("لا يوجد حسابات في القيد");
    } else {
      setSubmitting(true);
      const model = {
        restriction: restrictData,
        _Data: alldata,
      };
      const save = await restrictionValidateSchema
        .validate(restrictData, { abortEarly: false })
        .then(async function (valid) {
          if (isNew) {
            await axios
              .post(uri + "RestrictionInsert", model)
              .then((res) => {
                toast.success("تمت اضافة القيد اليومي");
                if (type == "print") print();
                newRestriction();
              })
              .catch((error) => {
                console.log("error api", error);
                toast.error("لم يتم إضافة القيد اليومي بسبب ال api");
              });
          } else {
            await axios
              .put(uri + "RestrictionInsert", model)
              .then((res) => {
                toast.success("تمت تعديل القيد اليومي");
                if (type == "print") print();
                newRestriction();
              })
              .catch((error) => {
                console.log("error api", error);
                toast.error("لم يتم إضافة القيد اليومي بسبب ال api");
              });
          }
        })
        .catch(function (err) {
          toast.error(err.errors[0]);
        });
      setSubmitting(false);
    }
  };

  const print = () => {
    const info = {
      data: alldata,
      tot: null,
      accBook: accBookValue.Name,
      doc_no2: restrictData.doc_no2,
      notes: restrictData.notes,
      total_dept: restrictData.total_dept,
      total_credit: restrictData.total_credit,
      nDate: moment(restrictData.date).format("YYYY-MM-DD"),
      tDate: "00:00:00",
    };

    localStorage.setItem("iInfos", JSON.stringify(info));
    his.push({ pathname: "/RP" });
    window.location.reload(false);
  };

  const onDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setSubmitting(true);
    await axios
      .delete(uri + `RestrictionInsert/${id}`)
      .then((res) => {
        toast.success("تم الحذف بنجاح");
        newRestriction();
      })
      .catch((error) => {
        toast.error(error);
      });

    setSubmitting(false);
  };

  const computerestrictData = () => {
    let total_dept = alldata
      .reduce((a, b) => (a = a + parseFloat(b.dept)), 0)
      .toFixed(2);
    let total_credit = alldata
      .reduce((a, b) => (a = a + parseFloat(b.credit)), 0)
      .toFixed(2);
    let total_difference = Math.abs(total_credit - total_dept).toFixed(2);

    setRestrictData({
      ...restrictData,
      total_dept,
      total_credit,
      total_difference,
    });
  };

  const newRestriction = async () => {
    if (accountBooks?.length > 0) {
      await axios
        .get(uri + "RestrictionInsert/newId")
        .then((res) => {
          setAlldata([]);
          setBranchValue(branches[0]);
          setAccBookValue(accountBooks[0]);
          setRestrictData({
            ...restrictDefaultValue,
            doc_no2: res.data,
            branch: branches[0].id,
            book_id: accountBooks[0].book_id,
          });
          setIsNew(true);
        })
        .catch((error) => {
          console.log("get newId error", error);
        });
    }
  };

  useEffect(() => {
    newRestriction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountBooks]);

  useEffect(() => {
    computerestrictData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alldata]);

  return (
    <div className={clasess.main}>
      <Toaster position="top-center" reverseOrder={true} />

      <Grid item spacing={1} className={clasess.root} container>
        <Grid item lg={2} md={2} sm={2} xs={5}>
          <Autocomplete
            value={branchValue}
            className={clasess.textField}
            onChange={(event, newValue) => {
              setBranchValue(newValue);
            }}
            id="branchValue"
            size="small"
            disableClearable={true}
            options={branches}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.Name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="الفرع"
                variant="outlined"
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item lg={2} md={2} sm={2} xs={4}>
          <Autocomplete
            className={clasess.textField}
            value={accBookValue}
            onChange={(event, newValue) => {
              setAccBookValue(newValue);
            }}
            loading={accountBkFetching}
            id="controllable-states-demo"
            size="small"
            disableClearable={true}
            options={accountBooks}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.Name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="نوع الدفتر"
                variant="outlined"
                size="small"
                fontSize="small"
              />
            )}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={3}>
          <TextField
            className={clasess.textField}
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label="قيد يدوي"
            name="nameE"
            value={restrictData.rest_type}
            InputLabelProps={{ shrink: true }}
            onChange={changetf}
          />
        </Grid>

        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label="رقم القيد "
            name="doc_no2"
            value={restrictData.doc_no2}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid className={clasess.textField} item lg={2} md={2} sm={2} xs={4}>
          <TextField
            fullWidth
            variant="outlined"
            id="date"
            label="التاريخ"
            type="date"
            size="small"
            format="YYYY-MM-DD"
            value={restrictData.date}
            onChange={changetf("date")}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={4}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  fullWidth
                  checked={state.checkedA}
                  onChange={handleChange}
                  name="checkedA"
                />
              }
              size="small"
              fontSize="small"
              label="اضافة سجل بعدالحفظ"
            />
          </FormGroup>
        </Grid>

        <Grid item lg={5} md={5} sm={5} xs={8}>
          <TextField
            className={clasess.textField}
            type="text"
            fullWidth
            variant="outlined"
            size="small"
            id="stay"
            label="بيان القيد "
            name="notes"
            value={restrictData.notes}
            InputLabelProps={{ shrink: true }}
            onChange={changetf("notes")}
          />
        </Grid>
      </Grid>
      <RestrictionAccountForm
        {...{
          addSubRestrictionFunc,
        }}
      />
      <Grid className={clasess.root} item container>
        <RestricSubTable
          {...{
            alldata,
            setAlldata,
            loading: loadingSubs,
          }}
        />
      </Grid>

      <Grid className={clasess.table} item container>
        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography color="textSecondary" component="p" style={sty}>
            الفارق :
            {restrictData.total_difference > 0 ? (
              <b
                style={{
                  paddingInline: 5,
                  color: "white",
                  backgroundColor: "red",
                }}
              >
                {restrictData.total_difference} {"  "}
                {restrictData.total_credit > restrictData.total_dept
                  ? "دائن"
                  : "مدين"}
              </b>
            ) : (
              <b>{restrictData.total_difference}</b>
            )}
          </Typography>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography color="textSecondary" component="p" style={sty}>
            اجمالي المدين :<b>{restrictData.total_dept}</b>
          </Typography>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography color="textSecondary" component="p" style={sty}>
            اجمالي الدائن :<b>{restrictData.total_credit}</b>
          </Typography>
        </Grid>

        <Grid item lg={2} md={2} sm={2} xs={3}>
          <Typography color="textSecondary" component="p" style={sty}>
            <b>
              {restrictData.total_difference > 0
                ? "قيد غير متوازن"
                : "قيد متوازن"}
            </b>
          </Typography>
        </Grid>
      </Grid>
      <RestrictionSearchModal
        {...{
          open: openSearchModal,
          type: 10,
          onClose: () => {
            setOpenSearchModal(false);
          },
          onRowClick: async (data) => {
            setRestrictData({
              ...data.restriction,
              date: moment(data.date).format("YYYY-MM-DD"),
            });
            setIsNew(false);
            setOpenSearchModal(false);
            setLoadingSubs(true);
            await loadRestrictionSubs(data.restriction?.id);
            setLoadingSubs(false);
          },
        }}
      />
      <div className={clasess.appbar2}>
        <Grid container>
          <AppBar size="small" color="primary" className={clasess.appBar}>
            <Toolbar>
              <Grid item lg={2} md={2} sm={3} xs={3}>
                <Button
                  className={clasess.buttonss}
                  variant="contained"
                  color="primary"
                  fontSize="large"
                  size="medium"
                  onClick={backHome}
                  startIcon={<CancelIcon />}
                >
                  {" "}
                  الخروج
                </Button>
              </Grid>
              <Grid item lg={2} md={2} sm={3} xs={1}>
                <Button
                  onClick={() => setOpenSearchModal(true)}
                  startIcon={<SearchIcon />}
                >
                  البحث{" "}
                </Button>
              </Grid>

              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
              ></IconButton>
              <div className={clasess.grow} />
              <IconButton color="inherit">
                <Button
                  className={clasess.buttonss}
                  variant="contained"
                  color="primary"
                  fontSize="large"
                  size="medium"
                  onClick={submiteRestriction("print")}
                  disabled={submitting}
                  startIcon={<PrintIcon />}
                >
                  طباعه
                </Button>{" "}
              </IconButton>
              {
                <>
                  <Button
                    className={clasess.buttonss}
                    variant="contained"
                    color="secondary"
                    fontSize="large"
                    size="medium"
                    startIcon={<Delete />}
                    disabled={submitting || isNew}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "؟هل تريد الحذف بالتأكيد",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(restrictData.id);
                        },
                      });
                    }}
                  >
                    {" "}
                    حذف
                  </Button>
                </>
              }
              <Button
                className={clasess.buttonss}
                variant="contained"
                color="primary"
                fontSize="large"
                size="medium"
                onClick={submiteRestriction("save")}
                startIcon={<SaveeIcon />}
                disabled={submitting}
              >
                {isNew ? "حـفـظ" : "حفظ التعديلات"}
              </Button>

              <Button
                variant="contained"
                edge="start"
                color="primary"
                fontSize="large"
                className={clasess.buttonss}
                startIcon={<PostAddIcon />}
                onClick={newRestriction}
              >
                {"جديد"}
              </Button>
            </Toolbar>
          </AppBar>
        </Grid>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
}
