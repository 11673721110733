import React, { useEffect, useState } from 'react';
//b
import axios from 'axios';
import {uri}  from '../../../../../help/Api';
import {insh,sto} from '../../../../../Context/InshData';
import moment from 'moment';
// rt
import CssBaseline from '@material-ui/core/CssBaseline';
// mt
// f e
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ScannerIcon from '@material-ui/icons/Scanner';
import KeyboardReturnSharpIcon from '@material-ui/icons/KeyboardReturnSharp';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Chip from '@material-ui/core/Chip';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';

// t
// import MaterialTable, { MTableToolbar, MTableBodyRow  } from "material-table";
import MaterialTable from "material-table";
// import tableIcons from "../../../../Elemnts/TableIcons";
import {TableIcons as tableIcons} from "../../../../../Elemnts/TableIcons";


import Container from '@material-ui/core/Container';
// cuG
import {useStylesUp,useStylesHed,useStylesAuto,useStylesTF,useStylesTD,useStylesBA,useStylesLbl
  ,useStylesUiElemment,
  useStylesSpDial} from '../../../../../St/Reports/UseStyle';
// cuL
// import {} from './UseStyle';
// csG
import {cellS,headerS,stElemnt} from '../../../../../St/comps/cStyle'
  


//=====================================================================

// let prm = {Ne:nl,Sa:nl,De:nl,Se:nl,Pr:nl,Up:nl}

export default function Body(){

  const clasup = useStylesUp();
  const clashd = useStylesHed();
  const clasBA = useStylesBA();
  const clasAo = useStylesAuto();
  const clasTF = useStylesTF();
  const clasTD = useStylesTD();
  const claslbl = useStylesLbl();
  const clasui = useStylesUiElemment();
  const clasSp = useStylesSpDial();
  
  const [valueAccno, setValueAccno] = useState({});

  
  const insOrders = [{id:1,name:'ترتيب حسب التاريخ'},{id:2,name:'ترتيب حسب رقم المستند'},];
  const [optionsOrder, setOptionsOrder] = useState(insOrders);
  const [valueOrder, setValueOrder] = useState(optionsOrder[0]);

  const insReviws = [{id:1,name:'إجمالي'},{id:2,name:'تفصيلي'},];
  const [optionsReviw, setOptionsReviw] = useState(insReviws);
  const [valueReviw, setValueReviw] = useState(optionsReviw[1]);


  var columns = [
    {title: "نوع الحركة", field: "title", },
    {title: "عدد الفواتير", field: "counts",},
    {title: "الإجمالي", field: "total" },
    {title: "نقدي", field: "total_cash",},
    {title: "آجل", field: "total_agel",},
    {title: "شبكة", field: "total_network",},
    {title: "بنك", field: "total_bank",},
    {title: "ذمم", field: "total_emp",},
  ];
  

   const inshrpt = {
    dateFrom:moment(new Date()).format('YYYY-MM-DD'),
    dateTo:moment(new Date()).format('YYYY-MM-DD'),
    branch:-1, user:-1,
    safe:-1,  stock:-1,
  }
  
const [rpt, setRpt] = useState(inshrpt);
  const [gets, setGets] = useState(insh.tbname);

// const load Rpt1 = async (dateFrom,dateTo) => {
//   console.log('dateFrom'+' , '+'dateTo')
//   console.log(dateFrom+' , '+dateTo)
// }

const inshValueAuto = {
  safe:{},stock:{},branch:{},user:{}
}
const [valueAutos, setValueAutos] = useState(inshValueAuto);


const reLoadRpt = async () => {
  const tb = {
    RptSalesDayle:true, wait:true,
    stor:sto,
    parms:{
      dateFrom:rpt.dateFrom, dateTo:rpt.dateTo,
      branch:rpt.branch, user:rpt.user,
      safe:rpt.safe, stock:rpt.stock,
    },
    // autos:{},
 }

// await axios.post('http://localhost:8184/api/Rept',tb)
await axios.post(uri+'Rept',tb)
  .then(res => {
    let salesDayle =res.data.SalesDayle.map(sd=>
      {
        return {
          ...sd, 
          total: sd.total.toFixed(2),
          total_cash: sd.total_cash.toFixed(2),
        }
      });

    setGets({...gets,SalesDayle:salesDayle,tot:res.data.tot});
}).catch(error => { /*console.log('no rpt');*/ })
}

      const loadRpt = async (firstTime, sBranch) => {
        const tb = {
          RptSalesDayle:true, wait:true,
          Safes:true,Stocks:true, Branches:true,Users:true,

          stor:{...sto, branchi: sBranch.id == -1 ? sto.branchi : sBranch.id},

          parms:{
            dateFrom:rpt.dateFrom, dateTo:rpt.dateTo,
            branch:rpt.branch, user:rpt.user,
            safe:rpt.safe,  stock:rpt.stock,
          } 
       }

 console.log('tb___'); console.log(tb)

      // await axios.post(uri+'Repts',tb)
      // await axios.post('http://localhost:8184/api/Rept',tb)
      await axios.post(uri+'Rept',tb)
        .then(res => {
          setGets(res.data);
          let newVA = {
            safe:res.data.Safes[0],
            stock:res.data.Stocks[0],
            user:res.data.Users[0],
          }
          if(firstTime){
            setValueAutos({
              ...newVA, 
              branch: res.data.Branches[0],
            })
          }else{
            setValueAutos({
              ...newVA,
              branch: sBranch,
            });
            setRpt({...inshrpt, branch: sBranch.id});
          }
          // setValueAutos({...valueAutos,
          //   safe:res.data.Safes[0],
          //   stock:res.data.Stocks[0],
          //   // branch:firstTime? res.data.Branches[0] : valueAutos.branch,
          //   user:res.data.Users[0],
          // })
      }).catch(error => { /*console.log('no rpt');*/ })
      }

      
useEffect(async () => {
  await loadRpt(true, {id: -1});
}, []);

const changAutos = (prop, newValue) =>
{
  setValueAutos({...valueAutos,[prop]:newValue})
  setRpt({ ...rpt, [prop]: newValue.id });
}

const changTField = (prop) => (e) => {
  // console.log(prop+' - '+e.target.value)
  setRpt({ ...rpt, [prop]: e.target.value });

  // rpt.safe=valueAutos.safe.id;
  // rpt.stock=valueAutos.stock.id;
  prop=='dateFrom' ? rpt.dateFrom=e.target.value : rpt.dateTo=e.target.value
  // reLoadRpt();
};

// ==== b sd
const [open, setOpen] = React.useState(false);
// const [hidden, setHidden] = React.useState(false);

const handleOpen = () => { setOpen(true); };
const handleClose = () => { setOpen(false); };

const toSave = () => { };

const toBack = () => { his.push({ pathname: '/', }); };


let his = useHistory();
const toPosh = (typ) => {
  const info = {
  columns:columns,
  // rout:rpt,
  data:gets.SalesDayle,
  tot:gets.tot,
  from:'الحساب',
  title:'تقرير ملخص الحركة اليومية',
  // auto:valueAccno.name,
  auto:valueAutos,
  fDate:rpt.dateFrom,tDate:rpt.dateTo,
  ElmntShow:true,
  // totsp:rpt.totSelcPeriod,balncsp:rpt.balncSelcPeriod
  }
  console.log('info'); console.log(info);
  const typeP = {tyPrnt:typ}
  localStorage.setItem('iInfos',  JSON.stringify(info));
  localStorage.setItem('typPrnt',  JSON.stringify(typeP));
  his.push({ pathname: '/ReviewEDay',});
  window.location.reload(false);
}

const actions = [
  // { icon: <FileCopyIcon />, name: 'Copy' },
  { icon: <CloseIcon className={[clasSp.ico,clasSp.clcan]} onClick={toBack} />, name: 'خـروج' },
  { icon: <SaveIcon className={clasSp.ico} onClick={reLoadRpt} />, name: 'عــرض' },
  { icon: <ScannerIcon className={clasSp.ico} onClick={()=>toPosh('A')} />, name: 'مـعـايـنـة كـبـيـر' },
  { icon: <PrintIcon className={clasSp.ico} onClick={()=>toPosh('C')} />, name: 'مـعـايـنـة كـاشـيـر' },
  // { icon: <ShareIcon />, name: 'Share' },
  // { icon: <FavoriteIcon />, name: 'Like' },
];

// ==== b sd 


const frontDownR = () => {
  return(
        <Grid container lg={12} md={12} className={[clasup.rootdown,]}>
        <Grid container xs={12} className={[clasup.downsub,clasup.downsubR,]}>
    
              {/* S front F */}
          <Grid container lg={7} md={7} sm={7} xs={7} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={12} >
            <Chip className={[clasui.chipT,clasui.chipTR]} label={'رصيد الصندوق للفترة'} clickable color="primary" /> 
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Chip className={[clasui.chipV,clasui.chipVR]} label={gets.tot.totRsedStok} clickable color="default"/>
            </Grid>
          </Grid>
    
          <Grid container lg={5} md={5} sm={5} xs={5} className={clasui.rootchip}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Chip className={[clasui.chipT,clasui.chipTL]} label={'رصيد الشبكة'} clickable color="primary" /> 
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
            <Chip className={[clasui.chipV,clasui.chipVL]} label={gets.tot.totRsedNetW} clickable color="default"/>
            </Grid>
          </Grid>
        {/* E front F */}
    
        </Grid>
        </Grid>
  )
}

return (
  <React.Fragment>
  <CssBaseline />
  <Container maxWidth="xl">
    <Typography component="div" 
    style={{textAlign:'center', backgroundColor: '#fff', height: '100vh',direction:'rtl' }}>

 <Grid container> 

    <Grid item xs={12}>
     <Chip size="small" className={clashd.ChipHdSale} label="تقرير الحركة اليومية" color="primary" />
    </Grid>

{/* S front Right */} 
<Grid container lg={5} md={5}>
    {/* S front up R */} 
    <Grid container lg={12} md={12} className={[clasup.rootup,]}>
    <Grid container spacing={1} xs={12} className={[clasup.upsub,clasup.upsubR,]}>
      
      <Grid item lg={6} md={6} sm={4} xs={6}>
      <TextField fullWidth variant="outlined" size="small" id="fd" label="من تاريخ"
      className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
      type="date" format="YYYY-MM-DD"
      name="fd" value={rpt.dateFrom} onChange={changTField('dateFrom')}
      /></Grid>

      <Grid item lg={6} md={6} sm={4} xs={6}>
      <TextField fullWidth variant="outlined" size="small" id="td" label="إلى تاريخ"
      className={clasTF.rootSale} InputProps={{ className: clasTD.input }}
      type="date" format="YYYY-MM-DD"
      name="td" value={rpt.dateTo} onChange={changTField('dateTo')}
      /></Grid>

      <Grid item lg={6} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo} 
                value={valueAutos.safe} 
                labelPlacement=""
                onChange={(event, newValue) => { changAutos('safe',newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Safes}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField {...params} label="المخزن" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid> 

      <Grid item lg={6} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo} 
                value={valueAutos.stock} 
                labelPlacement=""
                onChange={(event, newValue) => { changAutos('stock',newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Stocks}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                // style={{  }}
                disableClearable
                renderInput={(params) => <TextField {...params} label="الصندوق" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid> 

      <Grid item lg={6} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo}
                value={valueAutos.branch}
                onChange={(event, newValue) => { 
                  // changAutos('branch', newValue);
                loadRpt(false, newValue);
              }}
                id="controllable-states-demo" size="small"
                options={gets.Branches}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                disableClearable
                renderInput={(params) => <TextField {...params} label="الفرع" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid>

      <Grid item lg={6} md={6} sm={4} xs={6}>
      <Autocomplete classes={clasAo}
                value={valueAutos.user}
                onChange={(event, newValue) => { changAutos('user', newValue) }}
                id="controllable-states-demo" size="small"
                options={gets.Users}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                disableClearable
                renderInput={(params) => <TextField {...params} label="المستخدم" variant="outlined" size="small" 
                InputLabelProps={{className:claslbl.clrSale}} />}
              />
      </Grid>

      <Hidden smDown={['sm']}>
      <Grid item container spacing={1} className={clasui.contbnt}>
        <Grid item >
        <Button variant="contained" startIcon={<PrintIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clPri]} onClick={reLoadRpt}>عرض النتيجة</Button>
        </Grid>
        <Grid item >
        <Button variant="contained" startIcon={<PrintIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clPri]} onClick={()=>toPosh('C')}>معاينة كاشير</Button>
        </Grid>
        <Grid item >
        <Button variant="contained" startIcon={<ScannerIcon />} color="primary" size="large"
          className={[clasui.button,clasSp.clSec]} onClick={()=>toPosh('A')}>معاينة كبير</Button>
        </Grid>
        <Grid item >
        <Button variant="contained" startIcon={<KeyboardReturnSharpIcon />} color="secondary" size="large"
          className={[clasui.button,clasSp.clcan]} onClick={toBack}>خروج</Button>
        </Grid>
      </Grid>
      </Hidden>

    </Grid></Grid>
   {/* E front up R */}
   {/* S front down R */} 
   <Hidden smDown={['sm']}>{frontDownR()}</Hidden>
  {/* E front down R */}
</Grid>
{/* E front Right */} 

{/* S front Left */} 
<Grid container lg={7} md={7}>
    {/* s front up L */}
    <Grid container lg={12} md={12} className={[clasup.rootup,]}>
    <Grid container xs={12} className={[clasup.upsub,clasup.upsubL,]}>
      <Grid item xs={12}>
           <MaterialTable style={{boxShadow:'none'}}
                title='بحث عن الفواتير'
            // variant="outlined"
              options={{ 
              pageSize: 7,
              //showTitle:false,
              toolbar: false,
              sorting: false,
              showTitle: false,

              // selection: true,
     // showTextRowsSelected: false,
              
               // act ionsCellStyle: -1,
                paging: false,
           
                headerStyle: headerS,
                cellStyle: cellS,

               //tableLayout: 'fixed',
              //  maxBodyHeight: '50vh',
              maxBodyHeight: '70vh',
              minBodyHeight: '70vh',

             }}
       
             localization={{
               
              body:{
                emptyDataSourceMessage:<h3 
                style={{ marginTop:'20vh', border:'solid 0px', boxShadow:'none'}}
                ></h3> } }}
             columns={columns}
             data={gets.SalesDayle}
             icons={tableIcons}
             
             onRowClick={(event, rowData) => {
              event.stopPropagation();
            }}/>
      </Grid>    
    </Grid>
    </Grid>
    {/* e front up L */}

    {/* S front down L */}
    {/* <Hidden smDown={['sm']}>
    <Grid container lg={12} md={8} className={[clasup.rootdown, clasup.grdhivh]} 
    >
    <Grid container xs={12} className={[clasup.downsub,clasup.downsubL,]}>
    </Grid> </Grid></Hidden> */}
    {/* E front down L */}

</Grid>
{/* E front Left */} 

  {/* S front down R */} 
  <Hidden mdUp={['md']}>{frontDownR()}</Hidden>
{/* E front down R */}

</Grid>



  {/* s front fixed */}
  <Hidden mdUp={['md']}>
  <div className={clasSp.rootsd}>
      <SpeedDial ariaLabel="SpeedDial openIcon example"
        className={clasSp.speedDialsd} hidden={false}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose} onOpen={handleOpen} open={open}>
        {actions.map((action) => ( <SpeedDialAction
            key={action.name} icon={action.icon}
            tooltipTitle={action.name} 
            // onClick={toPosh} 
            />))}
      </SpeedDial>
    </div>
    </Hidden>
  {/* e front fixes */}

    </Typography>
  </Container>

</React.Fragment>
 );
 
}