import { makeStyles } from "@material-ui/core";
export const sty = {
    marginLeft: '20px',
    marginRight: '10px',
    marginBottom: '6px',
  }

export const useStyles = makeStyles((theme) => ({
    main:{
      padding: theme.spacing(1),
  
    },
    appbar2:{
     // padding: theme.spacing(1),
      // margin: theme.spacing(1),
      margin: theme.spacing(0,1),
  
      // right:20,
      // left:20
      justifyContent:'center'
  
    },
    textField44: {
      // margin: 10,
      // margin: theme.spacing(1),
      width: '100%',
      margin: 5,
    },
    textField: { "& .MuiInputLabel-outlined.MuiInputLabel-shrink": { color:'navy', fontSize:'0.8rem', },
    "& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)": { color:'navy', fontSize:'0.7rem',
     lineHeight: '0.5rem',}, "& .MuiOutlinedInput-inputMarginDense": 
    { paddingLeft:5,paddingRight:5, paddingTop:7,paddingBottom:7, textAlign:'center', fontSize: '1rem' },},
  
    buttons: {
      margin: theme.spacing(1),
      marginBottom: 25
    },
    root: {
      direction: 'rtl',
      marginBottom: theme.spacing(1),
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',
    },
    notess: {
      marginRight: 20,
      marginTop: 12,
      margin: theme.spacing(1),
    },
    GridButton: {
      margin: theme.spacing(1),
  
    },
    table: {
      direction: 'rtl',
      boxShadow: 'rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;',
      border: 'solid 1px #009688',
      marginBottom:80,
     // margin: theme.spacing(1),
      padding: theme.spacing(1),
    },
    text: {
      padding: theme.spacing(2, 2, 0),
    },
    paper: {
      paddingBottom: 50,
    },
    list: {
      marginBottom: theme.spacing(2),
    },
    subheader: {
      backgroundColor: theme.palette.background.paper,
    },
    appBar: {
      top: 'auto',
      right:'0.10%',
      bottom:3,
      left: '0.5%',
      width: '98.9%',
      backgroundColor: '#808080  ',
      marginRight:50,
      marginLeft:5,
      paddingRight:20
  
    },
    buttonss:{
      backgroundColor: '#808080',
    },
    grow: {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
    fabButton: {
      position: 'absolute',
      zIndex: 1,
      top: -30,
      left: 0,
      right: 0,
      margin: '0 auto',
    },
  
  
  }));