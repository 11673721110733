import React, { useState,useEffect } from 'react';

import axios from 'axios';
import {uri, }  from '../../../../help/Api';
import {Rept}  from '../../../../Context/InshData';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import {useRptAF} from '../../../../St/Reviews/UseStyle';
import { useLocation } from 'react-router-dom';


 function HeaderA () {
  const cls = useRptAF();
  const location = useLocation();
  const [state, setState] = useState({});
  const getInfo = async () => {
  axios.get(uri+'Foundation').then( res => { setState(res.data); }).catch(error => { });
}

useEffect(() => {  
  getInfo();
}, []);

return (

      <Grid item container className={cls.root}>
        <Grid item xs={5} className={cls.rootsub}>
          <Typography>{state.nameE}</Typography>
          <Typography>{state.FieldE}</Typography>
        </Grid>
        <Grid item xs={2} >
         <ButtonBase>
         <img src={`data:image/jpeg;base64,${state.Logo}`} className={cls.logo} alt={state.nameA}/> 
         </ButtonBase>
        </Grid>
        <Grid item xs={5} className={cls.rootsub}>
          <Typography>{state.nameA}</Typography>
          <Typography>{state.FieldA}</Typography>
        </Grid>

      <Grid item container className={[cls.title,cls.titleS]}>
      <Grid item xs={12} >
         <Typography >  <b> الاقرار الضريبي  </b></Typography>
        </Grid>
        </Grid>
        <Grid item container className={[cls.title,cls.title2]}>

          <Grid item xs={3} className={cls.tlR}>{location.state.dateTo}</Grid>
          <Grid item xs={3}  > {' الى تاريخ'}</Grid>
          <Grid item xs={3} >{location.state.dateFrom}</Grid>
          <Grid item xs={3} >{'من تاريخ '}</Grid>

        </Grid>      

    </Grid>
  );

  }
export default HeaderA;


