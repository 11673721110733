import React from "react";
import useFetchURL from "../../../../components/Hooks/useFetchURL";
import { uri } from "../../../../help/Api";
import useFormDataCRUD from "../../../../components/Hooks/useEDSCURD";
import API from '../../../../api/UsersCURD';
import User from "./user";
import useFetchObjectByPost from "../../../../components/Hooks/useFetchObjectByPost";
import { useEffect } from "react";
import { insh, sto } from "../../../../Context/InshData";
import BackdropScI from "../../../../services/BackdropScI";
import BackdropSc from "../../../../services/BackdropSc";

export default function UserCon(){


    const {data, fetching, fetchURL} = useFetchURL({...{
        url:API.URL+`?userId=${sto.idi}`,   
    }});

    // const {object:data, fetching: fetchinData, fetch:fetchURL} =  useFetchObjectByPost(url, tb);
    useEffect(()=>{
        console.log('uers is', data)
    })
   
      if(fetching || !data?.length > 0 )
        return <>
        <h1>
        هذه الواجهة متوفرة فقط للادمين
      </h1> 
         <BackdropSc />
        </> 

    return (
        <User
        {...{
            data,
            fetchURL,
        }}/>
    )
}

   